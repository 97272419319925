import { getApi } from '../axios';

export async function getProducts({ shopId, id, accessKey, queryString }) {
  return await (
    await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
  ).get(
    `/shops/${shopId}/groups/${id}/products?accessKey=${accessKey}&size=${queryString?.batchSize}&page=${queryString?.pageParam}&sort=name,asc`
  );
}

export async function getReferenceImage({ shopId, imageId }) {
  return await (
    await getApi({
      baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
      auth: true
    })
  ).get(`admin/shops/${shopId}/reference-pictures/${imageId}`);
}
