import styled from 'styled-components';
import { IoMdHeartEmpty } from 'react-icons/io';
import { IoMdHeart } from 'react-icons/io';
import { forwardRef } from 'react';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import SIZES from 'constants/sizes';
import Slide from '@material-ui/core/Slide';

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 1100px) {
    flex-direction: row;
  }

  @media (max-width: 1099px) {
    & > *:first-child {
      align-items: center;
    }
  }
`;

export const Title = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
`;

export const ProductName = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.palette.primary.backgroundPrimary};
  margin: 1rem 1rem 0.5rem 1rem;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.main}, Sans-serif, Arial;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: '1200px',
      margin: '16px',
      borderRadius: '12px',
      overflow: 'hidden',
      background: '#fff',
      boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06)'
    }
  },
  dialogContent: {
    padding: '1rem',
    maxHeight: '90vh',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: '32px'
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none'
  },
  productDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',

    [theme.breakpoints.up('sm')]: {
      padding: '32px'
    }
  },
  dialogHeader: {
    marginBottom: '24px'
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#111827',
    fontSize: '1.25rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  groupNameText: {
    color: '#4B5563',
    fontWeight: 500,
    '&:hover': {
      color: '#111827'
    }
  },
  breadcrumbSeparator: {
    color: '#9CA3AF',
    margin: '0 4px',
    userSelect: 'none'
  },
  productNameText: {
    color: '#111827',
    fontWeight: 600,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    display: 'inline-block',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  sectionTitle: {
    color: '#374151',
    fontWeight: 500,
    marginBottom: '16px',
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    letterSpacing: '0.05em'
  },
  stepperContainer: {
    flex: 1
  },
  stepper: {
    padding: '0',
    marginBottom: '32px',
    '& .MuiStepLabel-label': {
      color: '#374151',
      '&.Mui-active': {
        color: '#111827',
        fontWeight: 600
      }
    },
    '& .MuiStepIcon-root': {
      color: '#FFB800'
    },
    '& .MuiStepIcon-completed': {
      color: '#FFB800 !important'
    },
    '& .MuiStepIcon-active': {
      color: '#FFB800 !important'
    },
    '& .Mui-disabled .MuiStepIcon-root': {
      color: '#E5E7EB'
    },
    '& .MuiStepLabel-labelContainer': {
      marginTop: '8px'
    }
  },
  stepLabel: {
    fontSize: '0.875rem',
    color: '#374151'
  },
  stepAnswer: {
    color: '#111827',
    fontWeight: 500
  },
  summary: {
    borderTop: '1px solid #E5E7EB',
    paddingTop: '24px',
    marginTop: '24px'
  },
  priceDetails: {
    marginBottom: '24px'
  },
  priceRow: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#374151',
    fontSize: '0.875rem',
    marginBottom: '8px',
    '&:last-child': {
      color: '#111827',
      fontWeight: 500,
      fontSize: '1rem'
    }
  },
  actionButtons: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px'
    }
  },
  continueButton: {
    color: '#4B5563',
    borderColor: '#D1D5DB',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 2
    },
    '&:hover': {
      borderColor: '#9CA3AF',
      background: 'rgba(0,0,0,0.04)'
    }
  },
  checkoutButton: {
    backgroundColor: '#FFB800',
    color: '#111827',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 1
    },
    '&:hover': {
      backgroundColor: '#E5A600'
    },
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#9CA3AF'
    }
  },
  skeleton: {
    borderRadius: '1rem',
    height: '100%',
    minHeight: '300px'
  },
  dialogContext: {
    marginTop: '1rem',
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 'bold',
    textTransform: 'uppercase !important',
    fontFamily: theme.typography.secondary,
    width: '100%'
  },
  actionsContainer: {
    marginTop: '24px',
    marginBottom: '16px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end'
  },

  fileName: {
    marginTop: '1rem',
    color: theme.palette.primary.backgroundPrimary,
    fontFamily: theme.typography.secondary,
    textAlign: 'center',
    width: '100%'
  },

  basketIcon: {
    position: 'absolute',
    zIndex: '70',
    top: '0.5rem',
    left: '0.5rem',
    fontSize: '1.5rem',
    color: theme.palette.primary.backgroundPrimary
  },
  button: {
    padding: '8px 16px',
    color: '#4B5563',
    borderColor: '#D1D5DB',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderColor: '#9CA3AF'
    },
    '&.Mui-disabled': {
      color: '#9CA3AF',
      borderColor: '#E5E7EB'
    }
  },
  toCartBtn: {
    padding: '8px 24px',
    backgroundColor: '#FFB800',
    color: '#111827',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E5A600'
    },
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#9CA3AF'
    }
  },
  payButton: {
    color: theme.palette.primary.textPrimary,
    background: theme.palette.primary.backgroundPrimary,
    '&:hover': {
      color: 'black',
      background: theme.palette.primary.primaryGrey
    }
  },
  totalPrice: {
    marginBottom: '0.5rem',
    color: theme.palette.primary.backgroundPrimary
  },
  spinner: {
    color: `black !important`
  },
  nextButton: {
    padding: '8px 24px',
    backgroundColor: '#FFB800',
    color: '#111827',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E5A600'
    },
    '&.Mui-disabled': {
      backgroundColor: '#E5E7EB',
      color: '#9CA3AF'
    }
  }
}));

export const ButtonsWrapper = styled.div`
  align-self: center;
  height: 1rem;
`;

export const HeartHolder = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0.5rem;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isLiked ? 1 : 0)};
`;

export const ProductCard = styled.div`
  cursor: pointer;
  background-color: var(--color-light) !important;
  border-radius: 1rem;
  z-index: 0;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 36px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
    opacity: ${(props) => (props.isLiked ? 1 : 0)};
    transition: opacity 0.3s ease;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    ${HeartHolder} {
      opacity: 1;
    }
  }
`;

export const Image = styled.img`
  border-radius: 1rem;
  transition: 0.3s all;
  width: 100%;
  display: block;
  &:hover {
    opacity: 80%;
  }
`;

export const MediaSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${SIZES.SM}px) {
    padding: 32px;
  }

  @media (min-width: 1100px) {
    align-items: flex-start;
  }
`;

export const DialogImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @media (min-width: ${SIZES.SM}px) {
    max-width: 500px;
  }
`;

export const CartIconWrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary.backgroundSecondary};
  position: absolute;
  border-radius: 0 0 0 0 4px;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  overflow: hidden;
  top: -1px;
  left: 0px;
  z-index: 20;
  width: 65px;
  height: 65px;
`;

const StyledFilledHearth = withTheme(styled(IoMdHeart)`
  fill: #ef4444;
  height: 24px;
  width: 24px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
`);

const StyledHearth = withTheme(styled(IoMdHeartEmpty)`
  fill: white;
  height: 24px;
  width: 24px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
`);

export const HeartIcon = ({ isLiked, toggleLike }) => {
  return (
    <HeartHolder isLiked={isLiked} onClick={(e) => toggleLike(e)}>
      {isLiked ? <StyledFilledHearth /> : <StyledHearth />}
    </HeartHolder>
  );
};
