import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  validUntil: {
    textAlign: 'center',
    color: 'black',
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    fontSize: '1.3rem',
    fontWeight: 'bold'
  }
}));

export const ProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2rem;
  align-items: center;
`;

export const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const IntroSection = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 45vh;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 50;

  @media (min-width: 768px) {
    min-height: 55vh;
  }
`;
export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
  filter: blur(5px);
  transition: transform 10s ease-out;
  animation: subtleZoom 10s ease-out infinite alternate;

  @keyframes subtleZoom {
    0% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1.2);
    }
  }
`;
export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.5) 100%);
    transition: opacity 0.3s ease;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  padding: 2rem 0;
  margin-top: auto;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0) 100%);
`;

export const Title = styled.h1`
  color: white;
  font-size: 2.5rem;
  margin: 0;
  margin-left: 2rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    font-size: 3.5rem;
  }
`;

export const GroupsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  margin-top: 1rem;
`;
export const BreadcrumbWrapper = styled.div`
  height: 56px;
  z-index: 100;
`;

export const BackgroundPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.palette.primary.backgroundSecondary} 0%,
    ${(props) => props.theme.palette.primary.backgroundPrimary} 50%,
    ${(props) => props.theme.palette.primary.backgroundSecondary} 100%
  );
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
