/** @format */

import { getApi } from 'api/axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useDocumentsApi = () => {
  const [loading, setLoading] = useState();
  const [documents, setDocuments] = useState();
  const [baseDocuments, setBaseDocuments] = useState();

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const getDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/shops/${shopId}/contracts`);
      const docs = data?.map((d) => d && { ...d, accepted: false });
      setDocuments(docs);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const downloadDocuments = async (contractId, name) => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/shops/${shopId}/contracts/${contractId}`, {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });
      const href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      setLoading(false);
      const a = document.createElement('a');
      a.download = `${name}.pdf`;
      a.href = href;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      setLoading(false);
    }
  };

  const getBaseDocuments = async () => {
    try {
      setLoading(true);
      const { data } = await (await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })).get(`/contracts/shared`);
      const docs = data?.map((d) => d && { ...d, accepted: false });
      setBaseDocuments(docs);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const downloadBaseDocuments = async (contractId, name) => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/contracts/${contractId}`, {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });
      const href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      setLoading(false);
      const a = document.createElement('a');
      a.download = `${name}.pdf`;
      a.href = href;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      setLoading(false);
    }
  };

  const openBaseDocument = async (contractId, linkRef, name) => {
    try {
      setLoading(true);
      const { data } = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_USER_URL })
      ).get(`/contracts/${contractId}`, {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });
      const href = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      return href;
    } catch (e) {
      setLoading(false);
    }
  };

  return {
    getDocuments,
    downloadDocuments,
    getBaseDocuments,
    downloadBaseDocuments,
    openBaseDocument,
    loading,
    documents,
    baseDocuments
  };
};
