import { AiFillMail } from 'react-icons/ai';

import { MdDescription, MdLocationOn } from 'react-icons/md';
import styled from 'styled-components';
import elutasito_nyilatkozat from 'assets/files/Elutasito_nyilatkozat-1.pdf';
import aszf_fotos from 'assets/files/aszf_DT_fotos.pdf';
import adatkezelesi_fotos from 'assets/files/adatkezelesi_DT_fotos.pdf';
import { Facebook, YouTube } from '@material-ui/icons';
import tiktokPng from 'assets/icons/tiktok_icon.png';

export const TikTokIcon = styled.img`
  width: 0.9rem;
  height: 0.9rem;
`;

export const useFooterData = (shopData) => {
  const FooterData = {
    sections: [
      // Currently unnecessary because there will be no registration without previous background check
      // {
      //   name: "Regisztráció",
      //   links: [
      //     {
      //       text: "Regisztrálj oldalunkra fotósként!",
      //       icon: (
      //         <AiFillCamera style={{ color: theme.palette.primary.error }} />
      //       ),
      //       link: window._env_.REACT_APP_QDAK_REGISTRATION_URL,
      //       external: true,
      //     },
      //   ],
      // },
      {
        name: 'Kapcsolat',
        links: [
          {
            text: 'ugyfelszolgalat@iskolaevkonyv.hu',
            icon: <AiFillMail style={{ color: 'var(--primary-background-secondary)' }} />,
            link: `mailto:ugyfelszolgalat@iskolaevkonyv.hu`,
            external: true
          },
          {
            text: 'Vedd fel a kapcsolatot!',
            icon: <MdLocationOn style={{ color: 'var(--primary-background-secondary)' }} />,
            link: '/contact'
          }
        ]
      },
      {
        name: 'GYIK',
        links: [
          {
            text: 'Kérdések és válaszok vásárlóknak',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            link: '/customer-faq'
          },
          {
            text: 'Kérdések és válaszok fotósoknak',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            link: '/photographer-faq'
          }
        ]
      },

      {
        name: 'Közösségi Média',
        links: [
          {
            text: 'Facebook',
            icon: (
              <Facebook
                style={{
                  fontSize: '1rem',
                  color: 'var(--primary-background-secondary)'
                }}
              />
            ),
            link: 'https://www.facebook.com/iskolaevkonyv',
            external: true
          },
          {
            text: 'TikTok',
            icon: <TikTokIcon src={tiktokPng} alt='TikTok icon' />,
            link: 'https://www.tiktok.com/@iskolaevkonyv?lang=hu-HU',
            external: true
          },
          {
            text: 'Youtube',
            icon: (
              <YouTube
                style={{
                  fontSize: '1rem',
                  color: 'var(--primary-background-secondary)'
                }}
              />
            ),
            link: 'https://www.youtube.com/watch?v=najPCuV4Xog&list=PLZEHXfwdiWILn0CciKCwL0ldC2kJ_WRiK',
            external: true
          }
        ]
      }

      // {
      //   name: "Adatvédelem",
      //   links: [
      //     {
      //       text: "Adatkezelési tájékoztató",
      //       icon: (
      //         <MdDescription
      //           style={{ color: theme.palette.primary.error }}
      //         />
      //       ),
      //       link: "data-transfer",
      //     },
      //   ],
      // },
    ],
    //shop
    //   ? [
    //       {
    //         name: "Fotósról",
    //         links: [
    //           {
    //             text: `${shopData?.name} bemutatkozó oldala`,
    //             icon: (
    //               <AiFillCamera
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: "/about-me",
    //           },
    //         ],
    //       },

    //       {
    //         name: "Kapcsolat",
    //         links: [
    //           {
    //             text: "Írj egy emailt!",
    //             icon: (
    //               <AiFillMail style={{ color: theme.palette.primary.error }} />
    //             ),
    //             link: `mailto:${shopData?.email}`,
    //             external: true,
    //           },
    //           {
    //             text: "Vedd fel a kapcsolatot!",
    //             icon: (
    //               <MdLocationOn
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: "/contact",
    //           },
    //         ],
    //       },
    //       shopData?.footerDescription?.sectionTitle && {
    //         name: shopData?.footerDescription?.sectionTitle,
    //         links: shopData?.footerDescription?.rows?.map((row) => {
    //           return { ...row, external: true, icon: getIcon(row.icon) };
    //         }),
    //       },

    //       {
    //         name: "Simplepay",
    //         links: [
    //           {
    //             text: "SimplePay Általános Felhasználási Feltételek",
    //             icon: (
    //               <MdDescription
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: "https://simplepay.hu/wp-content/uploads/2021/09/SimplePay_b2c_aff_hun_eng_20211001.pdf",
    //             external: true,
    //           },
    //         ],
    //       },
    //     ]
    //   : [
    //       {
    //         name: "Regisztráció",
    //         links: [
    //           {
    //             text: "Regisztrálj oldalunkra!",
    //             icon: (
    //               <AiFillCamera
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: window._env_.REACT_APP_QDAK_REGISTRATION_URL,
    //             external: true,
    //           },
    //         ],
    //       },
    //       {
    //         name: "Kapcsolat",
    //         links: [
    //           {
    //             text: "Írj nekünk egy emailt!",
    //             icon: (
    //               <AiFillMail style={{ color: theme.palette.primary.error }} />
    //             ),
    //             link: "mailto:ugyfelszolgalat@iskolaevkonyv.hu",
    //             external: true,
    //           },
    //           {
    //             text: "Vedd fel a kapcsolatot!",
    //             icon: (
    //               <MdLocationOn
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: "contact",
    //           },
    //         ],
    //       },

    //       {
    //         name: "Simplepay",
    //         links: [
    //           {
    //             text: "SimplePay Általános Felhasználási Feltételek",
    //             icon: (
    //               <MdDescription
    //                 style={{ color: theme.palette.primary.error }}
    //               />
    //             ),
    //             link: "https://simplepay.hu/wp-content/uploads/2021/09/SimplePay_b2c_aff_hun_eng_20211001.pdf",
    //             external: true,
    //           },
    //         ],
    //       },
    //       // {
    //       //   name: "Adatvédelem",
    //       //   links: [
    //       //     {
    //       //       text: "Adatkezelési tájékoztató",
    //       //       icon: (
    //       //         <MdDescription
    //       //           style={{ color: theme.palette.primary.error }}
    //       //         />
    //       //       ),
    //       //       link: "data-transfer",
    //       //     },
    //       //   ],
    //       // },
    //     ],
    downloadSections: [
      {
        name: 'Dokumentumok',
        links: [
          // {
          //   name: "Adatkezelési tájékoztató",
          //   icon: <MdDescription />,
          //   href: adatkezelesi_tajekoztato,
          //   download: "iskolaevkonyv_adatkezelesi_tajekoztato",
          // },
          {
            text: 'Elutasító nyilatkozat',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            href: elutasito_nyilatkozat,
            download: 'iskolaevkonyv_elutasito_nyilatkozat'
          },
          {
            text: 'Minta ÁSZF fotósoknak',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            href: aszf_fotos,
            download: 'iskolaevkonyv_aszf_minta'
          }
        ]
      },
      {
        name: 'Adatvédelem',
        links: [
          {
            text: 'Minta adatkezelési tájékoztató fotósoknak',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            href: adatkezelesi_fotos,
            download: 'iskolaevkonyv_adatkezelesi_tajekoztato_minta'
          }
        ]
      },
      {
        name: 'Simplepay',
        links: [
          {
            text: 'SimplePay Általános Felhasználási Feltételek',
            icon: <MdDescription style={{ color: 'var(--primary-background-secondary)' }} />,
            link: 'https://simplepay.hu/wp-content/uploads/2021/09/SimplePay_b2c_aff_hun_eng_20211001.pdf',
            external: true
          }
        ]
      }
    ]
  };

  return {
    FooterData
  };
};
