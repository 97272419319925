import React from 'react';
import queries from '../../api/queries/ShopTierQueries';
import styles from './BundlesViewer.module.css';
import BundlesCard from './BundlesCard/BundlesCard';
import { useQuery } from '@tanstack/react-query';

const BundlesViewer = () => {
  const fetchShopTiersQuery = useQuery({
    queryKey: ['publicTiers'],
    queryFn: () => queries.getPublicShopTiers(),
    retry: (failureCount, error) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.title}>Regisztráció után elérhető csomagok</h1>
      {fetchShopTiersQuery?.data && (
        <div className={styles.cardContainer}>
          {fetchShopTiersQuery?.data.data
            ?.sort((a, b) => a?.id - b?.id)
            ?.map((el, idx) => {
              return <BundlesCard bundleData={el} popular={el?.id === 3} />;
            })}
        </div>
      )}
    </div>
  );
};

export default BundlesViewer;
