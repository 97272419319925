import { useGroupAPI } from 'pages/Group/hooks/useGroupAPI';
import { setShop } from 'redux/slices/ShopSlice';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { errorToast } from 'components/Toast/Toast';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { CircularProgress, Paper } from '@mui/material';
import styles from './EmbeddedLanding.module.css';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';

const EmbeddedLanding = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fetchGroupBySearchKey } = useGroupAPI();
  const searchKey = useLocation()?.search?.split('=')[1];

  const redirectToGroup = (groupRes) => {
    const path = `gallery/${groupRes.id}`;

    history.push(path, {
      accessKey: searchKey,
      groupId: groupRes.id
    });
  };

  const checkPassword = async () => {
    await fetchGroupBySearchKey({
      searchKey: searchKey
    })
      .then((res) => {
        dispatch(setShop(res?.data?.shopId));
        redirectToGroup(res?.data);
      })
      .catch(() => {
        errorToast(
          'A galéria nem elérhető a megadott keresőkulccsal, ellenőrizd a beírt kulcs helyességét. Ammenyiben mindent rendben találtál, de a galériát továbbra sem sikerül elérni, kérjük vedd fel a kapcsolatot a fotóssal!'
        );
        history.push('/');
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      checkPassword();
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchKey]);

  return (
    <div className={styles.mainContainer}>
      <Paper className={styles.paper}>
        <div className={styles.portraitInnerContainer}>
          <img className={styles.portraitImage} src={logoSvg} alt='Portrait of the photographer' />
        </div>
        <h1 className={styles.title}>Üdvözlünk az Iskolaévkönyv oldalán!</h1>
        <p className={styles.description}>
          Amennyiben mindent rendben találunk, átirányítunk az általad keresett galéria oldalára, köszönjük türelmed!
        </p>
        <CircularProgress size={80} style={{ color: 'whitesmoke' }} />
      </Paper>
    </div>
  );
};

export default EmbeddedLanding;
