import blende_grey from '../../../../assets/images/svg/blende_grey.svg';
import styles from './IntroSection.module.css';
import GroupCodeField from 'components/Group/GroupCodeField/GroupCodeField';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import Center from 'components/General/center/Center';

const IntroSection = () => {
  return (
    <div className={styles.introSection}>
      <div className={styles.introSectionInner}>
        <Center element='section'>
          <div className={styles.heroSection}>
            <div className={styles.introText}>
              <h1>
                Üdvözlünk a <u className={styles.underline}>digitalizált iskolai fotózás</u> világában!
              </h1>
              <p>
                Rendeléshez kérjük add meg a kapott hozzáférési kódot, majd kattints a Belépésre,{' '}
                <a href='#customer_workflow' style={{ color: 'white' }}>
                  további információkért
                </a>{' '}
                pedig görgess lejjebb!
              </p>
              <GroupCodeField />
            </div>
            <div className={styles.portraitWrapper}>
              <div className={styles.portraitInner}>
                <img className={styles.portraitImage} src={logoSvg} alt='Portrait of the photographer' />
              </div>
              <div className={styles.introTitle}>Iskolaévkönyv</div>
            </div>
          </div>
        </Center>

        <div
          className={styles.blendeImageWrapper2}
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '200%',
            backgroundPositionX: '-12rem',
            backgroundPositionY: '-5.5rem'
          }}
        />
        <div
          className={styles.blendeImageWrapper}
          style={{ background: `url(${blende_grey})`, backgroundSize: '172%' }}
        />
      </div>
    </div>
  );
};

export default IntroSection;
