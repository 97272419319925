/** @format */

import Center from 'components/General/center/Center';
import styles from './PhotographerInfoPage.module.css';
import { Helmet } from 'react-helmet';
import { MdAccessTime, MdLaptopMac, MdPeople, MdAttachMoney, MdPhone, MdEmail, MdMail } from 'react-icons/md';
import photographer from 'assets/images/svg/photographer_banner1.svg';
import automization from 'assets/images/svg/photographer_banner2.svg';
import splogo from 'assets/images/png/splogo.png';
import blende_grey from 'assets/images/svg/blende_grey.svg';
import help from 'assets/images/svg/photographer_help.svg';
import billingologo from 'assets/images/png/billingologo.png';
import szamlazzhulogo from 'assets/images/png/szamlazzhulogo.png';
//import barionlogo from 'assets/images/png/barionlogo.png';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MovingIconWidget from 'components/MovingIconWidget/MovingIconWidget';
import Chip from '@mui/material/Chip';
import { FaFacebook, FaTiktok } from 'react-icons/fa';
import ReactPlayer from 'react-player/lazy';
import BundlesViewer from 'components/BundlesViewer/BundlesViewer';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';

const PhotographerInfoPage = (props) => {
  const target = useRef(null);
  const history = useHistory();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === '#csomagok')
      target.current.scrollIntoView({
        behavior: 'smooth'
      });
    else window.scrollTo(0, 0);
  }, [hash]);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Fotósokat keresünk</title>
        <meta
          name='description'
          content='Keressük azokat a fotósokat akiket érdekel az iskolai fotózás, bölcsődei fotózás, vagy bármilyen oktatási intézményi fotózás. Egyszerűsítsd le a menedzsmentbeli terheket, és használj egy modern platformot.'
        />
      </Helmet>
      <section className={styles.header_section}>
        <div
          className={styles.blendeImageWrapper}
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '172%',
            backgroundPositionX: '0rem',
            backgroundPositionY: '-8rem'
          }}
        />
        <div
          className={styles.blendeImageWrapper2}
          style={{
            background: `url(${blende_grey})`,
            backgroundSize: '200%',
            backgroundPositionX: '-12rem',
            backgroundPositionY: '0rem'
          }}
        />
        <Center element='section' sx={styles.custom_center} color={'var(--primary-background-secondary)'}>
          <div className={styles.hero}>
            <div>
              <h1 className={styles.header_title}>Fotósokat keresünk !</h1>
              <div style={{ color: 'black' }}>
                Te is szeretsz fotózni? Szeretnél intézményi fotózást végezni? Könnyen bánsz a gyermekekkel?
                <br />
                <br /> Ha kipróbáltad már az <b>intézményi fotózást</b> biztosan Te is tapasztaltad, hogy{' '}
                <b>mennyi adminisztrációval jár</b>. Akár bölcsődében, akár óvodában, akár általános és közép iskolában
                fotózol, sajnos az intézményi fotózással járó plusz munkák egyformán érintenek. <br />
                <br />
                Kinek hiányzik a több száz egyéni megrendelőlap elkészítése és azok kezelése? Egyeztetés a tanárokkal,
                szülőkkel, pénz beszedés, probléma megoldás?
                <br />
                <b>
                  Ezekre ad megoldást az Iskolaévkönyv, mely a legtöbb adminisztrációs folyamatot nemcsak digitalizálja,
                  hanem automatizálja is.
                </b>
              </div>
              <div className={styles.buttons}>
                <button
                  onClick={() =>
                    target.current.scrollIntoView({
                      behavior: 'smooth'
                    })
                  }
                  className={styles.introButton}>
                  Így szeretnék dolgozni
                </button>
                <button onClick={() => history.push('/oktatasi-intezmenyeket-keresunk')} className={styles.otherButton}>
                  Intézményeknek
                </button>
              </div>
            </div>
            <div className={styles.photographer_image}>
              <img
                alt='Fotósokat keresünk az iskolaévkönyvhöz.'
                src={photographer}
                width={300}
                height={300}
                className={styles.photographer_image}></img>
            </div>
          </div>
        </Center>
      </section>
      <Center element='section' sx={styles.custom_center} color='rgb(241, 245, 249)'>
        <div className={styles.container}>
          <div className={styles.section_padding + ' ' + styles.problems}>
            <h2>Milyen lesz az életed fotósként az Iskolaévkönyvvel?</h2>

            <p>
              Lássuk a főbb különbségeket a jelened és a jövőd között, ami annál érzékelhetőbb lesz számodra, minél
              régebb óta vagy már a szakmában:
            </p>
            <ul className={styles.list}>
              <li>
                Többlet bevételed fog keletkezni pusztán abból kifolyólag, hogy a vásárlóid egy sokkal
                felhasználóbarátabb felületen tudnak válogatni és rendelni a fotókból. Ez{' '}
                <b>akár +30%-os bevételnövekedést</b> is jelenthet.
              </li>
              <li>
                Sokkal kevesebbet fogsz kijárni az iskolákba, és a tanárokkal sokkal <b>kevesebbet kell egyeztetned</b>.
                Ami megmarad, az a fotózás és a folyamat legvégén a fotók kiosztása.
              </li>
              <li>A szülői panaszkezelés is egyszerűsödik a rendszer használatával.</li>
              <li>
                Átlagosan úgy tapasztaltuk, hogy <b>50% időt tudsz megspórolni</b> ha az Iskolaévkönyv rendszerét
                használod.
              </li>
              <li>Végre a fotózásra koncentrálhatsz, amiért valószínű az egészet elkezdted.</li>
              <li>
                Sok pozitív visszajelzést fogsz kapni elégedett szülőktől, tanároktól, és egyre{' '}
                <b>több helyre fognak hívni</b>.
              </li>
              <li>
                Nem kell tartanod semmilyen ellenőrzéstől, legyen az GDPR, NAV vagy más jogi ellenőrző szerv, mert
                mindennek megfelelő, szabályos folyamataid lesznek.
              </li>
              <li>
                <b>A fotók értékesítése is egyszerűsödik.</b> Teljesen megváltozik a vállalkozásod pénzügyi dinamikája,
                Te is sokkal motiváltabb leszel.
              </li>
            </ul>

            <div className={styles.widget_container}>
              <div>
                <MovingIconWidget icon={<MdAccessTime title='Idő' fill='white' />} title={'Idő'} direction='up' />
              </div>
              <div>
                <MovingIconWidget
                  icon={<MdAttachMoney title='Bevétel' fill='white' />}
                  title={'Bevétel'}
                  direction='up'
                />
              </div>
              <div>
                <MovingIconWidget
                  icon={<MdPeople title='+100%' fill='white' />}
                  title={'Elégedettség'}
                  direction='up'
                />
              </div>
              <div>
                <MovingIconWidget
                  icon={<MdLaptopMac title='Adminisztráció' fill='white' />}
                  title={'Adminisztráció'}
                  direction='down'
                />
              </div>
            </div>
          </div>
        </div>
      </Center>
      <Center element='section' sx={styles.custom_center}>
        <div className={styles.container}>
          <div>
            <img
              alt='Leegyszerűsítjük az intézményi fotózást!'
              src={automization}
              className={styles.camera_image}></img>
          </div>
          <div className={styles.section_padding + ' ' + styles.problems}>
            <h2>Automatizáció = Megszűnő feladatok!</h2>
            <p>
              Igen, jól hallod, lesz sok olyan feladat, ami megszűnik, mert a rendszer oldja meg, neked semmi dolgod nem
              lesz velük. Mik ezek?
            </p>
            <div className={styles.chips}>
              <Chip label='Megrendelő lap készítés' />
              <Chip label='Fotók átméretezése' />
              <Chip label='Fotók tömeges másolgatása' />
              <Chip label='Megrendelő lapok kiosztása' />
              <Chip label='Digitális formátumban kért fotók kiküldése' />
              <Chip label='Kitöltött megrendelő lapok összeszedése' />
              <Chip label='Számlázás' />
              <Chip label='Pénz számolás, ellenőrzés' />
              <p>Ha az Iskolaévkönyv labor szolgáltatását választod, akkor ezeket is a rendszer oldja meg:</p>
              <Chip label='Képek nyomtatása' />
              <Chip label='Képek szortírozása' />
              <Chip label='Képek csomagolása' />
              <Chip label='Rendelési blokk a csomagba' />
              <Chip label='Osztályonként ellenőrző lista a kiosztáshoz' />
            </div>
            <p className={styles.more}>
              <button
                className={styles.introButton}
                onClick={() =>
                  target.current.scrollIntoView({
                    behavior: 'smooth'
                  })
                }>
                Én is így akarok dolgozni
              </button>
            </p>
          </div>
        </div>
      </Center>
      <Center element='section' sx={styles.custom_center} color='rgb(241, 245, 249)'>
        <div className={styles.section_padding}>
          <h2>Háttér szolgáltatások</h2>
          <p>
            Az Iskolaévkönyv folyamatos fejlesztését és üzemletetését a Datatronic Kft látja el. A cég 20 éve elismert
            IT vállalatként ma saját adatközponttal és fejlesztő csapattal rendelkezik. A legmodernebb cloud native
            technológiákkal biztosítja a gyors és zökkenőmentes rendszer elérést mind az adminisztrációs, mind a
            vásárlói felülethez.
            <br />
            <br /> Nálunk nem lesznek performancia problémák! A rendszer kapacitása a felhasználók számával együtt nő,
            amelyre Te is nyugodtan rábízhatod magad és a vállalkozásod! Folyamatosan bővítjük a rendszer funkcióit, és
            kiemelkedő fotós szakmai háttérszolgáltatás csomagot kínálunk. Profi fotósként nincs másra szükséged csak a
            fényképezőgépedre, <b>minden más terhet a rendszer levesz a válladról</b>.
            <br />
            <br />
          </p>
          <div className={styles.choices}>
            <div className={styles.logos}>
              <div>
                <img alt='Simple pay logo' src={splogo} />
              </div>
              {/* <div>
                                <img alt="Barion logo" src={barionlogo} />
                            </div> */}
              <div>
                <img alt='Számlázz.hu logo' src={szamlazzhulogo} />
              </div>
              <div>
                <img alt='Billingo logo' src={billingologo} />
              </div>
            </div>
            <p>
              Ha megvannak a saját kapcsolataid, laborod, partnereid, akkor dolgozhatsz velük tovább az Iskolaévkönyv
              használata mellett is.
            </p>
            <div>
              <h3>Rugalmas beállítási lehetőségek</h3>
              <ul className={styles.list}>
                <li>
                  Kétféle számlázási mód: <b>manuális</b> vagy <b>automatikus</b>
                </li>
                <li>
                  Többféle integrált számlázási platform: <b>számlázz.hu</b> vagy <b>Billingo</b>
                </li>
                <li>
                  Bankkártyás fizetési mód: <b>Simple Pay</b>
                </li>
                {/* <li>
                                    Többféle bankkártyás fizetési mód:{" "}
                                    <b>Simple Pay</b> vagy <b>Barion</b>
                                </li> */}
                <li>
                  Többféle labor kapcsolat: <b>Iskolaévkönyv labor</b> vagy saját laborhoz{' '}
                  <b>univerzális fotó kimenet</b>
                </li>
                <li>Általad meghatározható nyomtatott képméretek</li>
                <li>Általad meghatározható egyéb saját termékek: pl. hűtőmágnes, kulcstartó, bögre, stb.</li>
                <li>Ársablonok kialakítása és egyedi árazási lehetőség</li>
              </ul>
            </div>
          </div>
        </div>
      </Center>
      <Center element='section' sx={styles.custom_center}>
        <div className={styles.section_padding} style={{ paddingBottom: '2rem' }}>
          <div className={styles.container}>
            <div className={styles.section_padding + ' ' + styles.problems}>
              <h2>Fotós szakmai támogatásra is számíthatsz</h2>
              Az Iskolaévkönyv terjedésével egy új fotós szakmai közösség is kiépült a rendszer köré, gyűlnek a
              tapasztalatok, az élmények, amelyeket rendszeresen megosztunk egymással és az újonnan érkezőkkel
              workshopjainkon, online és offline eseményeinken, videóinkban. Találsz közöttünk fiatal kezdő fotósokat és
              több évtizede a szakmában lévő öreg rókákat is. Tanulunk egymástól, segítjük egymást, miközben{' '}
              <b>a folyamatos fejlődéshez profi hátteret nyújt a Datatronic és az Iskolaévkönyv</b>.
            </div>
            <div>
              <img alt='Szakmai támogatás' src={help} className={styles.help_image}></img>
            </div>
          </div>
          <div>
            Jelenleg ezeken a platformokon tudsz velünk kapcsolatban maradni:
            <div className={styles.logos} style={{ marginTop: '1rem' }}>
              <a className={styles.socialIcons} href='#hirlevel'>
                {' '}
                <MdMail style={{ marginRight: '0.5rem' }} /> Hírlevél
              </a>
              <a
                className={styles.socialIcons}
                href='https://www.facebook.com/iskolaevkonyv'
                target='_blank'
                rel='noreferrer'>
                {' '}
                <FaFacebook style={{ marginRight: '0.5rem' }} /> Facebook
              </a>
              <a
                className={styles.socialIcons}
                href='https://www.tiktok.com/@iskolaevkonyv'
                target='_blank'
                rel='noreferrer'>
                {' '}
                <FaTiktok style={{ marginRight: '0.5rem' }} /> TikTok
              </a>
            </div>
            <br />
            <br />
            Ha felkeltettük érdeklődésedet és szeretnéd használni oldalunkat a regisztráció gombra kattintva
            csatlakozhatsz hozzánk! Regisztráció után ingyenes csomagunkat egyből használhatod, ahol teszt környezetben
            próbálhatod ki az Iskolaévkönyv nyújtotta lehetőségeket.
            {/* <br />
            <br />
            <ReactPlayer url={window._env_.REACT_APP_EMBEDDED_REGISTRATION} controls={true} width={'100%'} /> */}
            <br />
            <br />
            <b>FONTOS!</b> A regisztráció ingyenes, fix előfizetési díjak csak az előfizetős csomagokban szerepelnek,
            profi csomagban pedig csak akkor fizetsz a rendszer használatért, amikor fotózol, és akkor is csak utólag,
            jutalékos alapon. A rendszer használati feltételeket és a jutalék mértékét az{' '}
            <a
              className={styles.styledLink}
              ref={target}
              href='/altalanos_szerzodesi_feltetelek'
              onClick={(e) => {
                e.preventDefault();
                history.push('/altalanos_szerzodesi_feltetelek');
              }}>
              ÁSZF
            </a>{' '}
            tartalmazza.
            <br /> Bátran próbáld ki, ismerd meg a rendszert és járj utána a{' '}
            <a
              className={styles.styledLink}
              ref={target}
              href='/photographer-faq'
              onClick={(e) => {
                e.preventDefault();
                history.push('/photographer-faq');
              }}>
              kérdéseidnek.
            </a>{' '}
            Keress minket elérhetőségeinken!
          </div>
          <br />
          <br />
          <div style={{ width: '100%' }}>
            <br />
            <a
              className={styles.contactButton}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '1rem auto',
                width: '180px'
              }}
              target='_blank'
              href={window._env_.REACT_APP_QDAK_REGISTRATION_URL}
              rel='noreferrer'>
              Fotós regisztráció
            </a>
          </div>
        </div>
      </Center>
      <div id={'csomagok'}>
        <BundlesViewer />
      </div>

      {/*
      NEEDED IN PHOTOGRAPHER PROFILE VERSION
      <EbookSubscriber />
      */}
      <Center element='section' sx={styles.custom_center} color={'var(--primary-background-secondary)'}>
        <div className={styles.section_padding}>
          <h2 style={{ color: 'black' }}>
            Nincs iskolád, vállalkozásod, de szeretnél fotózni? <br />
            Jelentkezz Iskolaévkönyv fotósnak!
          </h2>
          <div className={styles.contact_container}>
            <MdPhone /> Telefonos elérhetőség: <b>+36 70 421 3483</b>
          </div>
          <div className={styles.contact_container}>
            <MdEmail /> Email: <b>regisztracio@iskolaevkonyv.hu</b>
          </div>
        </div>
      </Center>
    </main>
  );
};

export default PhotographerInfoPage;
