/** @format */

import { getApi } from 'api/axios';
import { errorToast, successToast } from 'components/Toast/Toast';
import { useCallback, useState } from 'react';

const getOrderApi = async () => {
  let originalApi = await getApi({
    baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL
  });
  let newApi = { ...originalApi };

  newApi.interceptors.response.use((value) => {
    let newValue = { ...value };
    return newValue;
  });

  return newApi;
};

export const useOrderAPI = (handleNext) => {
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const postOrder = useCallback(async ({ order, shop }) => {
    try {
      setSubmitted(true);
      setOrder(null);
      setLoading(true);
      const res = await (await getOrderApi()).post(`/shops/${shop?.id}/orders`, order);
      console.log(res.data);
      if (!shop?.shopTier?.onlinePayment && res.data) {
        setOrder(res.data);
        handleNext();
        localStorage.removeItem('cartId');
        localStorage.removeItem('cartShopId');
      }
      if (res.data.paymentRedirectURL) {
        localStorage.clear();
        setOrder(res.data);
        setSubmitted(false);
        successToast('Sikeres rendelés leadás!');
        setLoading(false);

        window.location.replace(res.data.paymentRedirectURL);
      } else {
        setSubmitted(false);
        successToast('Sikeres rendelés leadás!');
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setSubmitted(false);
      errorToast('Sikertelen rendelés leadás, kérjük próbáld újra!');
      //Csak amíg backend oldalon nincs meg hogy sikeres legyen az order létrehozás
      //handleNext();
    }
  }, []);

  return {
    order,
    postOrder,
    loading,
    submitted,
    setSubmitted
  };
};
