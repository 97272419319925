import React from 'react';
import logoSvg from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import styles from './BundlesCard.module.css';
import { MdCheck, MdClose, MdInfo } from 'react-icons/md';
import BundleDialog from '../BundleDialog/BundleDialog';
import { Tooltip } from '@mui/material';

const BundlesCard = ({ bundleData, popular }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className={styles.outsideCard}>
      <div className={popular ? styles.mainContainerOutlined : styles.mainContainer}>
        {popular && <div className={styles.current_banner}>Legnépszerűbb</div>}
        <div className={styles.innerPart}>
          <div className={styles.logoContainer}>
            <img src={logoSvg} alt='logo' />
          </div>
          <h2>{bundleData?.name}</h2>
          <div className={styles.infoSection}>
            <div className={styles.info_item}>
              Galéria létrehozás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Képfeltöltés: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Saját termék definiálás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Rendelési határidő beállítás:{' '}
              {bundleData?.galleryExpiryDate ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Online fizetés:{' '}
              {bundleData?.onlinePayment ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Árazási sablon létrehozás:{' '}
              {bundleData?.pricingTemplates ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Automata számlázás:{' '}
              {bundleData?.automaticInvoice ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Galéria limit: <span>{`${bundleData?.galleryLimit} db`}</span>
            </div>
            <div className={styles.info_item}>
              Képfeltöltési limit: <span>{`${bundleData?.productsLimit} db`}</span>
            </div>
          </div>
          {bundleData.recurringPrice > 0 ? (
            <>
              <div className={styles.payment}>{bundleData.recurringPrice} Ft/hó</div>
              <div className={styles.payment}>{bundleData.recurringPriceYearly} Ft/év</div>
            </>
          ) : bundleData.commissionRate > 0 ? (
            <>
              <div className={styles.payment}>Részesedés: {bundleData.commissionRate * 100} %</div>
              <Tooltip
                title={`Prémium csomag esetén elengedjük a szokásos havidíjakat. Helyette egy jutalékos rendszer lép életbe, ahol csak az értékesítéseid után fizetsz, az értékesített összeg ${
                  bundleData.commissionRate * 100
                }%-át. További részleteket az Általános Szerződési Feltételek között találhatsz erről.`}>
                {' '}
                <div className={styles.payment}>
                  <MdInfo size={20} />
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <div className={styles.payment}>0 Ft/hó</div>
              <div className={styles.payment}>0 Ft/év</div>
            </>
          )}
          <button className={styles.introButton} onClick={() => setOpen(true)}>
            {'Csomag részletei'}
          </button>
          <BundleDialog bundleData={bundleData} open={open} setOpen={setOpen} />
        </div>
      </div>
    </div>
  );
};

export default BundlesCard;
