import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background.paper};
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; // 16:9 aspect ratio for smaller height
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  ${CardWrapper}:hover &::after {
    opacity: 1;
  }
`;

export const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${CardWrapper}:hover & {
    transform: scale(1.05);
  }
`;

export const CardContent = styled.div`
  padding: 0.75rem;
  background: ${(props) => props.theme.palette.primary.backgroundSecondary}; // Yellow background
`;

export const CategoryBadge = styled.span`
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 2rem;
  font-size: 0.75rem;
  backdrop-filter: blur(4px);
  z-index: 1;
`;

export const CardPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.palette.primary.backgroundSecondary};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  transition: opacity 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    width: 64px;
    height: 64px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'%3E%3C/circle%3E%3Cpolyline points='21 15 16 10 5 21'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.3;
  }

  ${CardWrapper}:hover & {
    opacity: 1;
  }
`;

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    flex: '0 0 calc(100% - 16px)',
    margin: '8px',
    maxWidth: '280px', // Back to smaller width
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 calc(50% - 16px)',
      maxWidth: '280px'
    },
    [theme.breakpoints.up('md')]: {
      flex: '0 0 calc(33.333% - 16px)',
      maxWidth: '280px'
    },
    [theme.breakpoints.up('lg')]: {
      flex: '0 0 calc(25% - 16px)',
      maxWidth: '280px'
    }
  },
  title: {
    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
    fontWeight: 600,
    fontSize: '0.875rem', // Back to smaller font
    color: theme.palette.primary.backgroundPrimary,
    textAlign: 'center',
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: '0.25rem 0'
  }
}));
