/** @format */
import styles from './ManualPaymentDetails.module.css';
import { IconButton } from '@mui/material';
import { MdOutlineContentCopy } from 'react-icons/md';

const ManualPaymentDetails = ({ order, shopData }) => {
  return (
    <div className={styles.container}>
      <div style={{ textAlign: 'center' }}>
        <div className={styles.iconWrapper}>
          <svg className={styles.successCheck} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
            <circle className={styles.successCheckCircle} cx='26' cy='26' r='25' fill='none' />
            <path className={styles.succcesCheckCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
          </svg>
        </div>
        <h1 className={styles.cardTitle}>Sikeres rendelés</h1>
      </div>

      <div className={styles.orderDetails}>
        <h2 className={styles.detailsTitle}>Köszönjük rendelésed!</h2>
        <p className={styles.detailsMessage}>
          A rendelésed sikeresen rögzítettük a rendszerünkben. A megrendelt termékeket a fotóssal egyeztetett módon
          tudod kifizetni. Amennyiben további kérdésed van a rendeléseddel és a fizetéssel kapcsolatban, kérjük vedd fel
          a kapcsolatot fotósoddal az alábbi elérhetőségek egyikén.
        </p>

        <div className={styles.detailRow}>
          <span className={styles.detailLabel}>Rendelési azonosító:</span>
          <span className={styles.detailValue}>{order?.id}</span>
          <IconButton className={styles.copyButton} onClick={() => navigator?.clipboard?.writeText(order?.id)}>
            <MdOutlineContentCopy />
          </IconButton>
        </div>

        <div className={styles.detailRow}>
          <span className={styles.detailLabel}>Név:</span>
          <span className={styles.detailValue}>
            {`${shopData?.owner?.contact?.lastname} ${shopData?.owner?.contact?.firstname}`}
          </span>
          <IconButton
            className={styles.copyButton}
            onClick={() =>
              navigator?.clipboard?.writeText(
                `${shopData?.owner?.contact?.lastname} ${shopData?.owner?.contact?.firstname}`
              )
            }>
            <MdOutlineContentCopy />
          </IconButton>
        </div>

        <div className={styles.detailRow}>
          <span className={styles.detailLabel}>E-mail cím:</span>
          <span className={styles.detailValue}>{shopData?.owner?.contact?.email}</span>
          <IconButton
            className={styles.copyButton}
            onClick={() => navigator?.clipboard?.writeText(shopData?.owner?.contact?.email)}>
            <MdOutlineContentCopy />
          </IconButton>
        </div>

        <div className={styles.detailRow}>
          <span className={styles.detailLabel}>Telefonszám:</span>
          <span className={styles.detailValue}>{shopData?.owner?.contact?.phone}</span>
          <IconButton
            className={styles.copyButton}
            onClick={() => navigator?.clipboard?.writeText(shopData?.owner?.contact?.phone)}>
            <MdOutlineContentCopy />
          </IconButton>
        </div>

        <div className={styles.detailRow}>
          <span className={styles.detailLabel}>Összeg:</span>
          <span className={styles.detailValue}>{`${order?.totalPrice?.grossAmount}Ft`}</span>
          <IconButton
            className={styles.copyButton}
            onClick={() => navigator?.clipboard?.writeText(order?.totalPrice?.grossAmount)}>
            <MdOutlineContentCopy />
          </IconButton>
        </div>
      </div>

      <p className={styles.detailsMessage} style={{ marginTop: '1.5rem' }}>
        A rendelésed részleteiről egy e-mail üzenetet is küldtünk a megadott e-mail címre. A fizetés feldolgozását
        követően visszaigazoló e-mailben fogunk tájékoztatni a sikeres rendelésről.
      </p>

      <div style={{ textAlign: 'center' }}>
        <a href='/' className={styles.homeButton}>
          Vissza a főoldalra
        </a>
      </div>
    </div>
  );
};

export default ManualPaymentDetails;
