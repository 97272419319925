import { Column, ColumnWrapper, GalleryStepper, GalleryStepperWrapper, OuterWrapper } from './styles';

import Product from 'components/Product/Product';
import { Container, Typography } from '@material-ui/core';
import { useProductsLogic } from './useProductsLogic';
import { IconButton } from '@mui/material';
import { ArrowBack, ArrowForward } from '@material-ui/icons';

const Products = ({ shopId, group, products, qdakAccessKey, lastRef }) => {
  const {
    columns,
    columnRef,
    pageData,
    pageRefs,
    handleBack,
    handleForward,
    galleryEndRef,
    currentPage,
    error,
    setError,
    galleryEndVisible
  } = useProductsLogic(products, qdakAccessKey, shopId);

  return (
    <OuterWrapper>
      {error && (
        <Container align='center'>
          <Typography color='error' style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            A képek betöltése során hiba történt...
          </Typography>
          <Typography color='error' style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Amennyiben nem látja kívánt képet,
          </Typography>
          <Typography color='error' style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            kérjük vegye fel a kapcsolatot fényképészével.
          </Typography>
        </Container>
      )}
      <ColumnWrapper>
        {columns &&
          products &&
          columns?.map((column, idy) => (
            <Column key={column.id + '_' + idy} ref={(el) => (columnRef.current[idy] = el)}>
              {column &&
                column?.map((item, idx) => {
                  return (
                    item && (
                      <div
                        key={item?.id + '_' + idx + ':' + idy}
                        id={item?.pageNumber}
                        ref={
                          pageData?.find((page) => page.centerEl == item?.id)
                            ? (el) =>
                                (pageRefs.current[pageData?.find((page) => page.centerEl == item?.id)?.pageNumber] = el)
                            : null
                        }>
                        <Product
                          setError={setError}
                          product={item}
                          key={item.id}
                          shopId={shopId}
                          lastRef={lastRef}
                          group={group}
                          qdakAccessKey={qdakAccessKey}
                        />
                      </div>
                    )
                  );
                })}
            </Column>
          ))}
        {columns && columns?.flatMap((el) => el)?.length > 20 && (
          <GalleryStepperWrapper style={{ opacity: galleryEndVisible ? '0' : '1' }}>
            <GalleryStepper>
              <IconButton onClick={() => handleBack()}>
                <ArrowBack />
              </IconButton>
              {currentPage + 1}
              <IconButton onClick={() => handleForward()}>
                <ArrowForward />
              </IconButton>
            </GalleryStepper>
          </GalleryStepperWrapper>
        )}
      </ColumnWrapper>
      <div ref={galleryEndRef}></div>
    </OuterWrapper>
  );
};

export default Products;
