import { Typography } from "@material-ui/core";
import styles from './ErrorPage.module.css';
import { Helmet } from "react-helmet";

const ErrorPage = (props) => {
  const { errorCode, errorText } = props;

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Hiba történt</title>
        <meta
          name="description"
          content="Valami hiba lépett fel, ha szükséges, vegye fel velünk a kapcsolatot!"
        />
      </Helmet>
      <div className={styles.container}>
        <Typography component="h1" variant="h1" className={styles.mainText}>
          {errorCode}
        </Typography>
        <Typography
          component="h2"
          variant="h2"
          className={styles.secondaryText}
        >
          {errorText}
        </Typography>
      </div>
    </main>
  );
};

export default ErrorPage;
