import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UseBreadcrumbData } from './hooks/UseBreadcrumbData';
import styles from './Breadcrumb.module.css';

export const Breadcrumb = ({ password, id, header, sticky, shopId }) => {
  const { fetchParentHeader, parentHeader } = UseBreadcrumbData();

  const history = useHistory();
  const [parentAccessKey, setParentAccessKey] = useState(null);

  const getParentAccessKey = () => {
    if (header?.belongsTo) {
      setParentAccessKey(localStorage.getItem(header?.id));
    } else {
      setParentAccessKey(null);
    }
  };

  const getParentHeader = () => {
    if (header?.belongsTo?.id !== undefined && parentAccessKey !== undefined) {
      fetchParentHeader({
        shopId: shopId,
        id: header?.belongsTo?.id,
        accessKey: parentAccessKey
      });
    }
    if (header?.belongsTo?.id !== undefined && parentAccessKey === undefined) {
      fetchParentHeader({
        shopId: shopId,
        id: header?.belongsTo?.id
      });
    }
  };
  const RouteChange = () => {
    history.push('/');
  };

  useEffect(() => {
    getParentHeader();
  }, [header?.belongsTo?.id, parentAccessKey]);

  useEffect(() => {
    getParentAccessKey();
  }, [header?.id]);

  const handleClick = () => {
    history.push(`/gallery/${header?.belongsTo.id}?accessKey=${parentAccessKey}`);
  };

  return (
    <div className={styles.breadcrumbWrapper}>
      <Breadcrumbs
        aria-label='breadcrumb'
        className={styles.breadcrumb}
        separator={<span style={{ color: 'var(--primary-text-primary)' }}> / </span>}>
        <Link color='inherit' onClick={RouteChange} className={styles.rootLink}>
          Főoldal
        </Link>
        <div className={styles.innerWrapper}>
          {header?.belongsTo ? (
            <Breadcrumbs aria-label='breadcrumb' className={styles.innerBreadcrumb}>
              {parentHeader?.belongsTo !== undefined && (
                <Typography
                  style={{
                    fontFamily: `var(--font-secondary), Sans-serif, Arial`
                  }}>
                  ...
                </Typography>
              )}
              <Typography
                style={{
                  fontFamily: `var(--font-secondary), Sans-serif, Arial`
                }}
                className={styles.link}
                onClick={handleClick}>
                {header?.belongsTo.name}
              </Typography>
              <Typography
                style={{
                  fontFamily: `var(--font-secondary), Sans-serif, Arial`
                }}>
                {header?.name}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Typography
              style={{
                fontFamily: `var(--font-secondary), Sans-serif, Arial`
              }}>
              {header?.name}
            </Typography>
          )}
        </div>
      </Breadcrumbs>
      <div className={styles.stickyWrapper} hidden={!sticky}>
        <Breadcrumbs aria-label='breadcrumb' sticky={sticky.toString()} className={styles.stickyBreadcrumb}>
          <Link color='inherit' onClick={RouteChange} className={styles.rootLink}>
            Főoldal
          </Link>
          <div className={styles.innerWrapper}>
            {header?.belongsTo ? (
              <Breadcrumbs aria-label='breadcrumb' className={styles.innerBreadcrumb}>
                {parentHeader?.belongsTo !== undefined && <Typography>...</Typography>}
                <Typography className={styles.link} onClick={handleClick}>
                  {header?.belongsTo.name}
                </Typography>
                <Typography>{header?.name}</Typography>
              </Breadcrumbs>
            ) : (
              <Typography>{header?.name}</Typography>
            )}
          </div>
        </Breadcrumbs>
      </div>
    </div>
  );
};
