import { Fragment } from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AddressSchema } from 'validation/Checkout/Schemas';
import styles from './AddressForm.module.css';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { useEffect } from 'react';
// import { useSelector } from 'react-redux';

const AddressForm = (props) => {
  // const isDemo = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.isDemo);

  useEffect(() => {
    // if (isDemo) {
    //   props.setPaymentMethod('DEMO_OTP_SIMPLE');
    // } else {
    props.setPaymentMethod('OTP_SIMPLE');
    // }
  }, []);

  const formik = useFormik({
    initialValues: {
      address: props.data.address,
      city: props.data.city,
      zip: props.data.zip,
      country: props.data.country ? props.data.country : 'Magyarország',
      saveAddress: props.data.saveAddress,
      submitted: props.data.submitted
    },
    validationSchema: AddressSchema,
    onSubmit: () => {
      props.setData(formik.values);
      props.handleNext();
    }
  });

  return (
    <Fragment>
      <div> Válassza ki melyik osztályban szeretné átvenni a képeket!</div>
      <br />
      <div>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <FormControl required style={{ width: '100%', rowGap: '0.5rem' }}>
              <Select
                autoFocus
                variant='outlined'
                aria-labelledby='deliveryTarget'
                name='deliveryTarget'
                value={props.deliveryTarget ?? 'Kérjük válasszon...'}
                style={{
                  fontFamily: `var(--font-secondary), Sans-serif, Arial`
                }}
                onChange={(e) => {
                  props.setDeliveryTarget(e.target.value);
                }}>
                <MenuItem
                  key={`kerjuk_valasszon`}
                  value={'Kérjük válasszon...'}
                  style={{
                    fontFamily: `var(--font-secondary), Sans-serif, Arial`
                  }}>
                  Kérjük válasszon...
                </MenuItem>
                {props?.deliveryTargets?.map((target) => {
                  return (
                    <MenuItem
                      key={`${target}option`}
                      value={target}
                      style={{
                        fontFamily: `var(--font-secondary), Sans-serif, Arial`
                      }}>
                      {target}
                    </MenuItem>
                  );
                })}
              </Select>
              {/* <RadioGroup
                  aria-labelledby="deliveryMethod"
                  name="deliveryMethod"
                  value={props.deliveryMethod}
                  defaultValue={"1"}
                  onChange={(e) => {
                    props.setDeliveryMethod(e.target.value);
                  }}
                  error={
                    formik.touched.deliveryMethod &&
                    Boolean(formik.errors.deliveryMethod)
                  }
                  helperText={
                    formik.touched.deliveryMethod && formik.errors.deliveryMethod
                  }
                >
                  {props.deliveryMethods.map((method) => {
                    return (
                      <div>
                        <FormControlLabel
                          disabled={method.key !== "1"}
                          key={method.key}
                          value={method.key}
                          control={<Radio color="primary" />}
                          label={method.name}
                        />
                      </div>
                    );
                  })}
                </RadioGroup>  THIS SECTION IS COMMENTED FOR QDAK'S SCHOOL VERSION*/}
            </FormControl>
            {/* <FormControl
                required
                style={{ margin: "1.5rem 0 1rem 0", width: "100%", rowGap: "0.5rem" }}
              >
                <FormLabel
                  style={{
                    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`, marginTop: "1rem"
                  }}
                >
                  Válassza ki a fizetés módját!
                </FormLabel>
                <Select
                  aria-labelledby="paymentMethod"
                  name="paymentMethod"
                  value={props.paymentMethod}
                  style={{
                    fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                  }}
                  defaultValue={"Kérjük válasszon..."}
                  onChange={(e) => {
                    props.setPaymentMethod(e.target.value);
                  }}
                >
                  <MenuItem
                    key={`kerjuk_valasszon`}
                    value={"Kérjük válasszon..."}
                    style={{
                      fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                    }}
                  >
                    Kérjük válasszon...
                  </MenuItem>
                  {props?.paymentMethods?.map((target) => {
                    return (
                      <MenuItem
                        key={target.key}
                        value={target.key}
                        style={{
                          fontFamily: `${theme.typography.secondary}, Sans-serif, Arial`,
                        }}
                      >
                        {target.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {props?.paymentMethod === 'TRANSFER' && <p style={{color: 'red'}}>
                Átutalásos fizetési mód esetén kérjük a fizetés 72 órán belül
                történő rendezését, a rendelés leadásától számítva. k
                Tájékoztatjuk, hogy az utalások feldolgozása
                2 munkanapot is igénybe vehet!
                </p>} */}
          </Grid>
        </Grid>

        <div className={styles.navigationButtons}>
          <Button variant='outlined' onClick={props.handleBack} type='button'>
            Vissza
          </Button>
          <Button
            className={styles.button}
            variant='contained'
            color='primary'
            onClick={props.handleNext}
            disabled={props.deliveryTarget === 'Kérjük válasszon...'}>
            Tovább
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default AddressForm;
