import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  useMediaQuery
} from '@mui/material';
import { errorToast, successToast } from 'components/Toast/Toast';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useRef, useState } from 'react';
import { SubscriptionSchema } from 'validation/Subscription/Schemas';
import jsonp from 'jsonp';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './FooterSubscribeForm.module.css';

const FooterSubscribeForm = () => {
  const [disabled, setDisabled] = useState(true);

  const [loading, setLoading] = useState(false);
  const captchaRef = useRef();
  const [data, setData] = useState({ firstname: '', lastname: '', email: '' });

  const history = useHistory();
  const target = useRef(null);
  const small = useMediaQuery('(max-width:1050px)');

  const [subSelected, setSubSelected] = useState({
    common: true,
    special: true
  });

  const handleChange = (e) => {
    setSubSelected({
      ...subSelected,
      [e.target.name]: e.target.checked
    });
  };

  const { common, special } = subSelected;
  const error = [common, special].filter((v) => v).length < 1;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email
    },
    validationSchema: SubscriptionSchema,
    onSubmit: async (e) => {
      console.log('submit');
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      if (token) {
        setLoading(true);
        const url = `https://iskolaevkonyv.us22.list-manage.com/subscribe/post-json?u=c270787a60e53d5f28392465f&amp;id=${window._env_.REACT_APP_MAILCHIMP_ID}&amp;f_id=${window._env_.REACT_APP_MAILCHIMP_FORMID}`;
        jsonp(
          `${url}&EMAIL=${data.email}&FNAME=${data.firstname}&LNAME=${data.lastname}&CATEGORY=${
            subSelected?.common && !subSelected?.special
              ? 'common'
              : !subSelected?.common && subSelected?.special
              ? 'photographer'
              : 'common, photographer'
          }&EBOOK=false`,
          { param: 'c' },
          (_, data) => {
            const { result } = data;
            if (result == 'success') {
              setData({
                email: '',
                firstname: '',
                lastname: ''
              });
              successToast('Sikeres feliratkozás!');
            }
            if (result == 'error') {
              errorToast('Sikertelen feliratkozás, kérjük próbáld újra!');
            }
            setLoading(false);
          }
        );
      }
    }
  });

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '1rem',
        marginTop: '2rem',
        borderRadius: '5px',
        width: small ? '90%' : 'calc(100% - 2rem)'
      }}>
      <h2 style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>Feliratkozom a hírlevélre</h2>
      <div>
        {' '}
        Feliratkozás-ra való kattintással egyidejűleg hozzájárulok, hogy a Datatronic Kft a nevemet és e-mail címemet
        hírlevelezési céllal kezelje és a részemre az iskolaévkönyv.hu rendszerrel és az iskolai fotózással kapcsolatban
        gazdasági reklámot is tartalmazó email hírleveleket küldjön, ill. kijelentem, hogy az{' '}
        {
          <a
            ref={target}
            href='/adatkezelesi_tajekoztato'
            style={{ color: 'var(--primary-background-secondary)' }}
            onClick={(e) => {
              e.preventDefault();
              history.push('/adatkezelesi_tajekoztato');
            }}>
            Adatvédelmi tájékoztatót
          </a>
        }{' '}
        elolvastam, és az abban foglaltakat megismertem.
      </div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          display: 'flex',
          rowGap: '1rem',
          flexDirection: 'column'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
          <TextField
            style={{ width: '100%', maxWidth: '450px', marginTop: '0.5rem' }}
            required
            variant='standard'
            id='firstname'
            name='firstname'
            label='Keresztnév'
            fullWidth
            autoComplete='given-name'
            value={formik.values.firstname}
            onChange={(e) => {
              formik.handleChange(e);
              setData({
                ...data,
                firstname: e.target.value
              });
            }}
            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
            helperText={formik.touched.firstname && formik.errors.firstname}
          />
          <TextField
            style={{ width: '100%', maxWidth: '450px', marginTop: '0.5rem' }}
            required
            variant='standard'
            id='lastname'
            name='lastname'
            label='Vezetéknév'
            fullWidth
            autoComplete='family-name'
            value={formik.values.lastname}
            onChange={(e) => {
              formik.handleChange(e);
              setData({
                ...data,
                lastname: e.target.value
              });
            }}
            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
            helperText={formik.touched.lastname && formik.errors.lastname}
          />
          <TextField
            style={{ width: '100%', maxWidth: '450px', marginBottom: '0.5rem', marginTop: '0.5rem' }}
            required
            variant='standard'
            fullWidth
            id='email'
            label='Email cím'
            name='email'
            autoComplete='email'
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              setData({
                ...data,
                email: e.target.value
              });
            }}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div style={{ width: '100%', maxWidth: '450px' }}>
            <p style={{ marginBottom: 0 }}>Ezek a témák érdekelnek:</p>
            <FormControl
              required
              error={error}
              component='fieldset'
              // sx={{ m: 3 }}
              variant='standard'>
              <FormGroup
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}>
                <FormControlLabel
                  control={<Checkbox checked={common} onChange={handleChange} name='common' />}
                  label='Általános információk'
                />
                <FormControlLabel
                  control={<Checkbox checked={special} onChange={handleChange} name='special' />}
                  label='Fotós információk'
                />
              </FormGroup>
              {error && <FormHelperText>*Válassz legalább egyet!</FormHelperText>}
            </FormControl>
          </div>
        </div>

        <ReCAPTCHA
          sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY}
          ref={captchaRef}
          onChange={() => {
            setDisabled(captchaRef?.current?.getValue() == '' || captchaRef?.current?.getValue() == undefined);
          }}
        />
        <Button
          style={{ width: '100px' }}
          disabled={disabled || loading || error}
          variant='contained'
          className={styles.button}
          type='submit'>
          {loading ? <CircularProgress size={24} /> : 'Küldés'}
        </Button>
      </form>
    </div>
  );
};

export default FooterSubscribeForm;
