import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  inputLabel: {
    color: '#111827',
    background: '#fff',
    padding: '0 4px',
    transform: 'translate(14px, -6px) scale(0.75)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    }
  },
  inputControl: {
    color: '#111827',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E5E7EB'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D1D5DB'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#FFB800'
    }
  },
  selectEmpty: {
    margin: theme.spacing(2)
  },
  actionsContainer: {
    marginTop: '24px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'flex-end'
  },
  button: {
    padding: '8px 16px',
    color: '#4B5563',
    borderColor: '#D1D5DB',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderColor: '#9CA3AF'
    },
    '&.Mui-disabled': {
      color: '#9CA3AF',
      borderColor: '#E5E7EB'
    }
  },
  nextButton: {
    padding: '8px 24px',
    backgroundColor: '#FFB800',
    color: '#111827',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#E5A600'
    },
    '&:disabled': {
      backgroundColor: '#E5E7EB',
      color: '#9CA3AF'
    }
  }
}));
