import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Dialog,
  Button,
  DialogContent,
  Typography,
  CircularProgress,
  useTheme,
  Zoom
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useProductAPI } from './hooks/useProductAPI';
import { useProductBL } from './hooks/useProductBL';
import SelectStep from './SelectStep/SelectStep';
import CountStep from './CountStep/CountStep';
import {
  DialogContentWrapper,
  Image,
  Transition,
  useStyles,
  ProductCard,
  HeartIcon,
  CartIconWrapper,
  DialogImage,
  ButtonsWrapper,
  MediaSection
} from './styles';
import { Skeleton, Tooltip } from '@mui/material';
import { formatPrice } from 'utils/Converters';

import { useHistory } from 'react-router-dom';
import MethodChooser from '../MethodChooser/MethodChooser';
import { useQuery } from '@tanstack/react-query';
import { getShop } from 'api/queries/UserQueries';

const Product = ({ shopId, product, group, qdakAccessKey, lastRef }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const [isvisible, setIsvisible] = useState(false);
  const [isInCart, setIsInCart] = useState();
  const [cartLoading, setCartLoading] = useState(false);
  const { fetchProductById, productById, fetchProductImage, productImage } = useProductAPI();
  const {
    toBoolean,
    calcSteps,
    open,
    toggleOpen,
    addToCart,
    toggleLike,
    calcPrice,
    calcTotalPrice,
    setQuantity,
    steps,
    setSteps,
    activeStep,
    setActiveStep,
    handleNext,
    handleBack,
    liked,
    setLiked,
    updateSteps
  } = useProductBL();

  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);

  const searchItems = () => {
    cart.items.forEach((item) => {
      if (parseInt(item.productId) === product?.id) {
        setIsInCart(true);
      }
    });
  };

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 15) {
      displayedName = name.slice(0, 7) + '...' + name.slice(name.length - 7, name.length);
    }

    return displayedName;
  };

  const { data: shopData } = useQuery({
    queryKey: ['shopData', shopId],
    queryFn: () => getShop({ shopId }),
    enabled: !!shopId
  });

  useEffect(() => {
    setIsvisible(false);
    setActiveStep(0);
  }, [open]);

  useEffect(() => {
    setIsInCart(false);
    searchItems();
  }, [cart, product]);

  useEffect(() => {
    fetchProductImage({
      shopId: shopId,
      accessKey: qdakAccessKey,
      id: product?.id
    });
  }, [product?.id, qdakAccessKey]);

  useEffect(() => {
    if (open === true) {
      fetchProductById({
        shopId: shopId,
        accessKey: qdakAccessKey,
        id: product?.id
      });
    }
  }, [open]);

  useEffect(() => {
    if (!productById?.modifiers) return;
    let newSteps = calcSteps(productById);
    setActiveStep(0);
    setSteps(newSteps);
  }, [productById]);

  useEffect(() => {
    if (activeStep >= 4) {
      setCartLoading(false);
    }
  }, [activeStep]);

  useEffect(() => {
    let isLiked = localStorage.getItem(product.id) === null ? false : toBoolean(localStorage.getItem(product.id));

    setLiked(isLiked);
  }, []);

  useEffect(() => {
    localStorage.setItem(product.id, liked);
  }, [liked, product.id]);

  const [hover, setHover] = useState(false);

  const formatProductTypeOptions = (step, productById) => {
    if (!step?.options) return [];

    return step.options.map((option) => ({
      id: option.id,
      name: option.name,
      description: option.description,
      price: option.unitPrice.grossAmount
    }));
  };

  return (
    <ProductCard
      theme={theme}
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={toggleOpen}
      isLiked={liked}>
      <CartIconWrapper style={{ display: !isInCart && 'none' }}>
        <ShoppingCartIcon className={classes.basketIcon} />
      </CartIconWrapper>
      {productImage ? (
        <Image src={productImage} alt={product?.alt} ref={lastRef} />
      ) : (
        <Skeleton variant='rectangular' width={'100%'} height={'100%'} className={classes.skeleton} />
      )}

      <HeartIcon hover={hover} isLiked={liked} toggleLike={(e) => toggleLike(e, product?.id)} />

      {open && (
        <Dialog
          open={productById ? open : false}
          TransitionComponent={Transition}
          keepMounted
          className={classes.dialog}
          onClick={(e) => e.stopPropagation()}
          onClose={toggleOpen}
          maxWidth='lg'
          fullWidth
          aria-labelledby='product-dialog-title'>
          <DialogContent className={classes.dialogContent}>
            <DialogContentWrapper onClick={(e) => e.stopPropagation()}>
              <MediaSection>
                <div className={classes.dialogHeader}>
                  <Typography variant='h5' component='h2' className={classes.breadcrumb}>
                    <span className={classes.groupNameText}>{group?.name}</span>
                    <span className={classes.breadcrumbSeparator}>/</span>
                    <Tooltip title={product?.name}>
                      <span className={classes.productNameText}>{calcName(product?.name)}</span>
                    </Tooltip>
                  </Typography>
                </div>
                <DialogImage src={productImage} alt={`${product.name}`} />
              </MediaSection>
              <div className={classes.productDetails}>
                <Typography variant='subtitle1' className={classes.sectionTitle}>
                  Elérhető variációk
                </Typography>

                <div className={classes.stepperContainer}>
                  <Stepper className={classes.stepper} activeStep={activeStep} orientation='vertical'>
                    {steps.map((step, index) => (
                      <Step key={step.name}>
                        <StepLabel>
                          <Typography className={classes.stepLabel}>
                            {step.name}
                            {step.answer !== null && (
                              <span className={classes.stepAnswer}>
                                : {productById?.modifiers.find((modifier) => modifier.id === step.answer)?.name}
                                {calcPrice(step.answer, productById) > 0 &&
                                  ` - ${formatPrice(calcPrice(step.answer, productById).toFixed(0))} HUF`}
                              </span>
                            )}
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          {step.name === 'Terméktípus' ? (
                            <div>
                              <MethodChooser
                                title=''
                                methods={formatProductTypeOptions(step, productById)}
                                selectedMethod={step.answer || ''}
                                onMethodChange={(answer) => {
                                  updateSteps(
                                    step,
                                    answer,
                                    productById?.modifiers.find((modifier) => modifier.id === answer)?.deliveryTypeDTO
                                  );
                                  handleNext();
                                }}
                                priceFormatter={(price) => `${formatPrice(price)} HUF`}
                                showDescription={true}
                                showPrice={false}
                              />
                              <div className={classes.actionsContainer}>
                                <Button
                                  variant='outlined'
                                  onClick={index === 0 ? toggleOpen : handleBack}
                                  className={`${classes.button} no-min-width`}>
                                  Vissza
                                </Button>
                                <Button
                                  variant='contained'
                                  disabled={step.optional ? false : !step.answer}
                                  onClick={() => {
                                    handleNext();
                                  }}
                                  className={classes.nextButton}>
                                  Tovább
                                </Button>
                              </div>
                            </div>
                          ) : (
                            step.options && (
                              <SelectStep
                                next={handleNext}
                                prev={handleBack}
                                toggle={toggleOpen}
                                shopId={shopId}
                                step={step}
                                steps={steps}
                                setAnswer={(answer) => {
                                  updateSteps(
                                    step,
                                    answer,
                                    productById?.modifiers.find((modifier) => modifier.id === answer)?.deliveryTypeDTO
                                  );
                                }}
                              />
                            )
                          )}
                        </StepContent>
                      </Step>
                    ))}
                    <Step key={'count_step'}>
                      <StepLabel className={classes.stepLabel}>
                        <Typography className={classes.stepLabel}>
                          {steps[0]?.deliveryType === 'EMAIL' || cartLoading ? 'Hozzáadás a kosárhoz' : 'Mennyiség'}
                        </Typography>
                      </StepLabel>
                      <StepContent className={classes.stepLabel}>
                        <CountStep
                          cartLoading={cartLoading}
                          format={steps[0]?.deliveryType}
                          setQuantity={setQuantity}
                        />
                        <div className={classes.actionsContainer}>
                          <Button
                            variant='outlined'
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}>
                            Vissza
                          </Button>
                          <Button
                            disabled={cartLoading}
                            onClick={() => {
                              addToCart(group, product, productById);
                              setSteps(calcSteps(productById));
                              setIsvisible(true);
                              setCartLoading(true);
                            }}
                            className={classes.toCartBtn}>
                            {cartLoading ? <CircularProgress size={20} className={classes.spinner} /> : 'Kosárba'}
                          </Button>
                        </div>
                      </StepContent>
                    </Step>
                  </Stepper>
                </div>

                <div className={classes.summary}>
                  <div className={classes.priceDetails}>
                    <Typography className={classes.priceRow}>
                      <span>Részösszeg:</span>
                      <span>{formatPrice(calcTotalPrice(product, productById).toFixed(0))} HUF</span>
                    </Typography>
                    <Typography className={classes.priceRow}>
                      <span>Teljes összeg:</span>
                      <span>{formatPrice(cart?.totalPrice?.grossAmount)} HUF</span>
                    </Typography>
                  </div>

                  <ButtonsWrapper>
                    <Zoom in={cartLoading ? false : isvisible} timeout={700} unmountOnExit>
                      <div className={classes.actionButtons}>
                        <Button
                          variant='outlined'
                          className={classes.continueButton}
                          onClick={() => {
                            setIsvisible(false);
                            setActiveStep(0);
                            toggleOpen();
                          }}>
                          Tovább vásárolok
                        </Button>
                        <Button
                          variant='contained'
                          className={classes.checkoutButton}
                          onClick={() => history.push('/cart')}>
                          {!shopData?.shopTier?.onlinePayment ? 'Rendelés' : 'Fizetek'}
                        </Button>
                      </div>
                    </Zoom>
                  </ButtonsWrapper>
                </div>
              </div>
            </DialogContentWrapper>
          </DialogContent>
        </Dialog>
      )}
    </ProductCard>
  );
};

export default Product;
