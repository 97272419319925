import { useState, useEffect } from 'react';
import background from 'assets/images/test.jpg';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { useDispatch, useSelector } from 'react-redux';
import { decrementAmountOfItem, incrementAmountOfItem, deleteItem, emptyCart } from 'redux/slices/CartSlice';
import { setShop } from 'redux/slices/ShopSlice';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import CartItem from './CartItem/CartItem';
import styles from './Cart.module.css';
import { Helmet } from 'react-helmet';
import { formatPrice } from 'utils/Converters';
import { useQuery } from '@tanstack/react-query';
import { getShop } from 'api/queries/UserQueries';

const Cart = ({ setAccessKey, setCartId, actions }) => {
  const history = useHistory();

  const items = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart.items);
  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);
  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const cartId = useParams()?.cartId;

  const accessKey = useLocation().search.split('=')[1];

  const [groups, setGroups] = useState({});

  const { data: shopData } = useQuery({
    queryKey: ['shopData', shopId],
    queryFn: () => getShop({ shopId }),
    enabled: !!shopId
  });

  const itemComparator = (a, b) => {
    if (a.modifiersText < b.modifiersText) {
      return -1;
    }
    if (a.modifiersText > b.modifiersText) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (accessKey && setAccessKey) {
      setAccessKey(accessKey);
    }
  }, [accessKey]);

  useEffect(() => {
    if (cartId && cartId) {
      setCartId(cartId);
    }
  }, [cartId]);
  useEffect(() => {
    if (cart?.shopId) {
      localStorage.setItem('shopId', cart?.shopId);
      dispatch(setShop(cart?.shopId));
    }
  }, [cart]);

  useEffect(() => {
    let newGroups = {};
    if (items && items.length > 0) {
      items.forEach((item) => {
        if (!newGroups[item.groupName]) {
          newGroups[item.groupName] = [];
        }
        newGroups[item.groupName].push(item);
      });
    }
    // Order
    let orderedGroups = {};
    Object.keys(newGroups)
      .sort()
      .forEach((key) => (orderedGroups[key] = []));
    Object.keys(newGroups).forEach((key) => {
      newGroups[key].sort(itemComparator).forEach((item) => {
        orderedGroups[key].push(item);
      });
    });
    setGroups(orderedGroups);
  }, [items]);

  const dispatch = useDispatch();
  /*const deliveries = [
    // TODO using usestate and GET-ing data from BE
    { id: 1, name: "GLS Futárszolgálat", price: 0 },
    { id: 2, name: "FoxPost automata", price: 0 },
    { id: 3, name: "DHL Futárszolgálat", price: 0 },
    { id: 4, name: "MPL Csomagpont", price: 0 },
  ];
  const [actualDelivery, setActualDelivery] = useState(deliveries[0]);*/

  const countTotalQuantity = () => {
    var total = 0;
    items.forEach((item) => (total += item.quantity));
    return total;
  };

  var totalQuantity = countTotalQuantity();
  const [alert, setAlert] = useState(false);
  const [actualItem, setActualItem] = useState(null);

  const toggleAlert = (item) => {
    if (alert) {
      dispatch(deleteItem({ itemToDelete: item }));
      setAlert(false);
    } else {
      setAlert(true);
      setActualItem(item);
    }
  };
  const incQuantity = (item) => {
    dispatch(incrementAmountOfItem({ itemToIncrement: item }));
  };

  const handleEmptyCart = () => {
    dispatch(emptyCart());
  };

  const handleBackToStore = () => {
    let path = '';
    if (localStorage.getItem('shopGalleryId')) {
      path = `/gallery/${localStorage.getItem('shopGalleryId')}`;
      history.push(path);
    } else if (!localStorage.getItem('shopGalleryId') && cart.items[0]) {
      path = `/gallery/${cart.items[0].groupId}?accessKey=${cart.items[0].accessKey}`;
      history.push(path);
    } else {
      path = '/';
      history.push(path);
    }

    // const path = localStorage.getItem("shopId")
    //   ? `/gallery/${localStorage.getItem("shopId")}`
    //   : "/";
    // history.push(path);
  };

  const decQuantity = (item) => {
    if (item.quantity === 1) {
      setAlert(true);
      setActualItem(item);
    }
    if (alert || item.quantity > 1) {
      dispatch(decrementAmountOfItem({ itemToDecrement: item }));
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'visible';
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Kosár</title>
        <meta
          name='description'
          content='Véglegesítse a digitális és nyomtatott képek megrendelését. Módosítsa a meglévő kosár tartalmát.'
        />
      </Helmet>
      <div style={{ margin: '1rem', marginTop: '3rem', marginBottom: '3rem' }}>
        <div className={`${styles.card} ${styles.cartWrapper}`}>
          <Zoom in={true} timeout={700} unmountOnExit>
            <div className={`${styles.cart} ${styles.itemsWrapper}`}>
              <div className={styles.headerAndItems}>
                <div className={styles.cartHeader}>
                  <h1 className={styles.cartTitle}>Az ön kosara</h1>
                  <span className={styles.itemCount}>{countTotalQuantity()} termék</span>
                </div>
                <div className={styles.groupList}>
                  {totalQuantity > 0 ? (
                    Object.keys(groups)?.map((key, idx) => {
                      return (
                        <div className={styles.groupWrapper} key={idx}>
                          <Typography noWrap className={styles.groupName} component='span'>
                            {key}
                          </Typography>
                          <div className={styles.itemList}>
                            {groups[key]?.map((item, idx) => (
                              <span key={idx}>
                                <CartItem
                                  groupName={item.groupName}
                                  name={item.productName}
                                  thumbnail={item.thumbnailURL || background}
                                  id={
                                    item?.modifiersText?.includes('keretezett képek')
                                      ? item?.modifiersText?.split('keretezett képek')?.splice(1)?.join(' ')
                                      : item?.modifiersText?.split(' ')?.splice(1)?.join(' ')
                                  }
                                  category={item?.modifierUri}
                                  size={''}
                                  count={item.quantity}
                                  decrement={() => decQuantity(item)}
                                  increment={() => incQuantity(item)}
                                  total={item.unitPrice.grossAmount?.toFixed(0) * item.quantity}
                                  delete={() => toggleAlert(item)}
                                />
                              </span>
                            ))}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={styles.emtpyCartMessage}>
                      <h3>Az ön kosara üres</h3>
                      <p>Térjen vissza a boltba és adjon termékeket a kosárhoz</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.buttonsWrapper}>
                <button
                  className={`${styles.cartButtonPrimary} ${styles.cartButton}`}
                  onClick={() => handleBackToStore()}>
                  Vissza a boltba
                </button>
                {totalQuantity > 0 && (
                  <button
                    className={`${styles.cartButtonOutlined} ${styles.cartButton}`}
                    onClick={() => handleEmptyCart()}>
                    Kosár ürítése
                  </button>
                )}
              </div>
            </div>
          </Zoom>
          <div className={styles.summaryWrapper}>
            <h2 className={styles.summaryTitle}>Összegzés</h2>

            <div className={styles.summaryItem}>
              <span className='label'>{countTotalQuantity()} termék</span>
              <span className='value'>{formatPrice(cart.totalPrice.grossAmount?.toFixed(0))} HUF</span>
            </div>

            <div className={styles.summaryItem}>
              <span className='label'>Teljes összeg</span>
              <span className='value'>{formatPrice(cart.totalPrice.grossAmount?.toFixed(0))} HUF</span>
            </div>

            <button
              className={styles.checkoutButton}
              onClick={() => history.push('/order-checkout')}
              disabled={totalQuantity <= 0 || actions?.find((action) => action === 'DISABLE_PAYMENT')}>
              {totalQuantity <= 0
                ? 'Az ön kosara üres.'
                : actions?.find((action) => action === 'DISABLE_PAYMENT')
                ? 'Fizetés nem elérhető'
                : !shopData?.shopTier?.onlinePayment
                ? 'Tovább a rendeléshez'
                : 'Tovább a fizetéshez'}
            </button>
          </div>
        </div>

        <Dialog
          open={alert}
          onClose={() => setAlert(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle className={styles.customDialogTitle} id='alert-dialog-title'>
            {'Biztosan törölni szeretnéd?'}
          </DialogTitle>
          <DialogActions className={styles.dialogActions}>
            <Button className={styles.customButton} onClick={() => toggleAlert(actualItem)} color='primary'>
              Törlés
            </Button>
            <Button className={styles.customButton} onClick={() => setAlert(false)} color='primary'>
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
};

export default Cart;
