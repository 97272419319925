/** @format */
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import Products from './Products/Products';
import GroupCard from 'components/Group/GroupCard/GroupCard';
import {
  ProductsWrapper,
  HomeWrapper,
  IntroSection,
  BackgroundWrapper,
  BackgroundImage,
  TitleContainer,
  Title,
  GroupsWrapper,
  BreadcrumbWrapper,
  BackgroundPlaceholder
} from './styles';
import { useMediaQuery } from '@material-ui/core';
import LoadIndicator from 'components/LoadIndicator/LoadIndicator';
import ShopNotificationDialog from 'components/ShopNotificationDialog/ShopNotificationDialog';
import { Helmet } from 'react-helmet';
import { useGroupLogic } from './useGroupLogic';
import ProfileWidget from 'components/ProfileWidget/ProfileWidget';
import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@material-ui/core';

const Group = (props) => {
  const mobile = useMediaQuery('(max-width: 480px)');
  const tablet = useMediaQuery('(min-width: 480px)');
  const desktop = useMediaQuery('(min-width: 992px)');
  const desktopXl = useMediaQuery('(min-width: 1200px)');
  const desktopXXl = useMediaQuery('(min-width: 1921px)');

  const {
    lastRef,
    infiniteProducts,
    getValidUntilFields,
    header,
    stickyBreadcrumb,
    location,
    shopId,
    cover,
    headerLoading,
    loading,
    groups,
    getQdakAccessKey,
    id
  } = useGroupLogic();

  const [showShopValidation, setShowShopValidation] = useState(false);
  const history = useHistory();

  const checkShopValidation = useCallback(() => {
    const currentShopId = shopId || localStorage.getItem('shopId');
    const cartShopId = localStorage.getItem('cartShopId');
    if (
      (cartShopId && currentShopId && +cartShopId !== +shopId) ||
      (header && cartShopId && header?.shopId != cartShopId)
    ) {
      setShowShopValidation(true);
      return false;
    }

    return true;
  }, [shopId, header?.shopId]);

  useEffect(() => {
    checkShopValidation();
  }, [checkShopValidation]);

  const handleCartNavigation = () => {
    history.push('/cart');
  };

  const handleEmptyCart = () => {
    localStorage.removeItem('cartId');
    localStorage.removeItem('cartShopId');
    window.location.reload();
  };

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Böngésző</title>
        <meta name='description' content='Böngéssz a képek között, hogy megtaláld a megfelelőt számodra.' />
      </Helmet>
      <BreadcrumbWrapper>
        <Breadcrumb
          header={header}
          sticky={stickyBreadcrumb}
          shopId={location.search === '' && shopId ? shopId : localStorage.getItem('shopId')}
        />
      </BreadcrumbWrapper>

      {headerLoading ? (
        <LoadIndicator height={900} />
      ) : (
        <HomeWrapper>
          <IntroSection>
            {/*
            NEEDED IN PHOTOGRAPHER PROFILE VERSION            
            <ProfileWidget /> */}
            <BackgroundWrapper>
              {cover ? <BackgroundImage src={cover} alt={header?.name} /> : <BackgroundPlaceholder />}
            </BackgroundWrapper>

            <TitleContainer>
              <Title>{header?.name}</Title>
            </TitleContainer>
          </IntroSection>
          {header?.modifiers && getValidUntilFields(header?.modifiers)}
          {loading ? (
            <LoadIndicator />
          ) : (
            <>
              {groups && (
                <GroupsWrapper id='groups'>
                  {groups
                    ?.sort((a, b) => {
                      return a.name.localeCompare(b.name);
                    })
                    .map((group, idx) => {
                      return (
                        <GroupCard
                          groupId={group?.id}
                          groupCategory={group.category}
                          key={idx}
                          groupName={group.name}
                          imgSrc={group.url}
                          belongsToId={header?.id}
                          qdakAccessKey={getQdakAccessKey}
                        />
                      );
                    })}
                </GroupsWrapper>
              )}

              {header?.belongsTo !== null && (
                <ProductsWrapper>
                  {infiniteProducts && (mobile || tablet || desktop || desktopXl || desktopXXl) && (
                    <Products
                      group={header}
                      qdakAccessKey={getQdakAccessKey()}
                      products={infiniteProducts}
                      lastRef={lastRef}
                      id={id}
                      shopId={location.search === '' && shopId ? shopId : localStorage.getItem('shopId')}
                    />
                  )}
                </ProductsWrapper>
              )}
            </>
          )}
        </HomeWrapper>
      )}
      <ShopNotificationDialog open={props.showValidUntil} setOpen={props.setShowValidUntil} />

      <Dialog
        open={showShopValidation}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setShowShopValidation(false);
          }
        }}
        disableEscapeKeyDown>
        <DialogTitle>Figyelmeztetés</DialogTitle>
        <DialogContent>
          <Typography>
            Ahhoz hogy ebben a galériában is válogathass, előbb meg kell venned a már kosárban lévő termékeket, vagy ki
            kell űrítened a kosarad! Megértésedet köszönjük!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCartNavigation} color='primary'>
            Irány a kosár
          </Button>
          <Button onClick={handleEmptyCart} color='primary'>
            Kosár ürítése
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default Group;
