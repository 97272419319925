import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import styles from './ItemCounter.module.css';
import { IconButton } from '@material-ui/core';

const ItemCounter = (props) => {
  return (
    <div
      className={styles.counterWrapper}
      style={{
        display: props?.category?.includes('email') ? 'none' : 'flex'
      }}>
      <IconButton
        className={styles.countButton}
        onClick={props.decrement}
        disabled={props.count <= 1}
        aria-label='decrease quantity'>
        <RemoveIcon />
      </IconButton>

      <div className={styles.countDisplay}>{props.count}</div>

      <IconButton className={styles.countButton} onClick={props.increment} aria-label='increase quantity'>
        <AddIcon />
      </IconButton>
    </div>
  );
};

export default ItemCounter;
