import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100px',
    [theme.breakpoints.up('md')]: {
      width: '100px'
    }
  },
  selectEmpty: {
    margin: theme.spacing(2)
  }
}));
