import * as React from 'react';
import styles from './BlogCard.module.css';
import BlogThumbnail from '../BlogThumbnail/BlogThumbnail';
import { useHistory } from 'react-router-dom';
import StatusChip from '../../blog/statusChip/StatusChip';
import { Button } from '@mui/material';
import huIcon from '../../../assets/icons/hu.png';
import enIcon from '../../../assets/icons/en.png';

const BlogCard = ({ title, date, img, url, buttonText, language }) => {
  const history = useHistory();
  const isNew = () => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7); // Subtract 7 days from current date

    return new Date(date) > oneWeekAgo;
  };

  return (
    <div className={styles.card}>
      <div className={styles.img_con}>
        <BlogThumbnail url={img} imgAlt={title} />
        <img
          className={styles.lang_icon}
          src={language === 'HU' ? huIcon : enIcon}
          height={20}
          width={30}
          alt={language}></img>
      </div>
      <div className={styles.card_content}>
        <div className={styles.content}>
          <div>
            <h3>{title}</h3>
          </div>
          <div>{new Date(date).toDateString()}</div>
        </div>
        <div className={styles.card_footer}>
          <Button
            variant='outlined'
            onClick={() => history.push(`blog/${url}`)}
            className={styles.button}
            sx={{
              color: '#FFB800',
              borderColor: '#FFB800',
              textTransform: 'none',
              '&:hover': {
                borderColor: '#E5A600',
                color: '#E5A600',
                backgroundColor: 'rgba(255, 184, 0, 0.04)'
              }
            }}>
            {buttonText}
          </Button>
        </div>
      </div>
      {isNew() && <StatusChip size='large' sx={styles.new_status} />}
    </div>
  );
};

export default BlogCard;
