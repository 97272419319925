export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);

    });

}

export const formatPrice = (price) => {
    if (!price) return '0';
  
    // Convert to string and remove any existing spaces
    const numStr = price.toString().replace(/\s/g, '');
  
    // Add spaces for thousands
    return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };
  