import { Dialog, DialogContent, Tooltip } from '@mui/material';
import React from 'react';
import styles from './bundleDialog.module.css';
import { MdCheck, MdClose, MdInfo } from 'react-icons/md';
import { useSelector } from 'react-redux';

const BundleDialog = ({ bundleData, open, setOpen }) => {
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        {shopTier?.id === bundleData?.id && <div className={styles.dialog_banner}>Aktuális előfizetés</div>}
        <DialogContent>
          <div className={styles.dialog_content}>
            <h2>{bundleData?.name}</h2>
            <div className={styles.tier_info_wrapper}>
              <div className={styles.detail_item}>
                <Tooltip title='Maximális létrehozható galériák száma. A limit elérése után új galériát nem tudsz létrehozni!'>
                  <p>
                    <MdInfo /> Galéria limit:
                  </p>
                </Tooltip>
                <p>{bundleData?.galleryLimit}db</p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Maximális feltölthető képek száma. A limit elérése után új képeket nem tudsz feltölteni!'>
                  <p>
                    <MdInfo /> Képfeltöltési limit:
                  </p>
                </Tooltip>
                <p>{bundleData?.productsLimit}db</p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség új galériák létrehozására a rendszerben'>
                  <p>
                    <MdInfo /> Galéria létrehozás:
                  </p>
                </Tooltip>
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Képek feltöltésének lehetősége a létrehozott galériákba'>
                  <p>
                    <MdInfo /> Képfeltöltés:
                  </p>
                </Tooltip>
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title="Személyre szabott termékkategóriák és termékek létrehozása, a 'Termékek és sablonok' felületen. Megadhatod pontosan milyen méretű és formátumú képeket szeretnél értékesíteni!">
                  <p>
                    <MdInfo /> Saját termék definiálás:
                  </p>
                </Tooltip>
                <p>
                  <MdCheck color='green' fontSize={22} />
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Részletes kimutatások és statisztikák megtekintése a galériák teljesítményéről, rendelésekről és bevételekről'>
                  <p>
                    <MdInfo /> Kimutatások:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.reports ? (
                    <MdCheck
                      color={
                        bundleData?.reports !== shopTier?.reports && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.reports !== shopTier?.reports && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség az nyomtatott termékek értékesítésére a galériákban, az oldal által biztosított nyomtató szolgáltatásokkal'>
                  <p>
                    <MdInfo /> Nyomtatási lehetőség:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.printOption ? (
                    <MdCheck
                      color={
                        bundleData?.printOption !== shopTier?.printOption && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.printOption !== shopTier?.printOption && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség rendelési határidő beállítására a galériákhoz. A határidő után a vásárlók nem tudnak új rendelést leadni'>
                  <p>
                    <MdInfo /> Rendelési határidő beállítása:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.galleryExpiryDate ? (
                    <MdCheck
                      color={
                        bundleData?.galleryExpiryDate !== shopTier?.galleryExpiryDate && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.galleryExpiryDate !== shopTier?.galleryExpiryDate && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A szülő galériák rendelési határidejének automatikus öröklése az algalériákra'>
                  <p>
                    <MdInfo /> Rendelési határidő öröklés:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.expiryDateInheritance ? (
                    <MdCheck
                      color={
                        bundleData?.expiryDateInheritance !== shopTier?.expiryDateInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.expiryDateInheritance !== shopTier?.expiryDateInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A szülő galériában beállított árak automatikus öröklése az algalériákra'>
                  <p>
                    <MdInfo /> Áröröklés:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.priceInheritance ? (
                    <MdCheck
                      color={
                        bundleData?.priceInheritance !== shopTier?.priceInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.priceInheritance !== shopTier?.priceInheritance && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Automatikusan generált galériák létrehozása csoportképek számára, amely megkönnyíti a képek rendszerezését és értékesítését'>
                  <p>
                    <MdInfo /> Automata csoportkép galéria generálás:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.groupGallery ? (
                    <MdCheck
                      color={
                        bundleData?.groupGallery !== shopTier?.groupGallery && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.groupGallery !== shopTier?.groupGallery && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőséget biztosít arra hogy a létrehozott galériákat, külön kereső kulccsal lehessen ellátni. A galériák külön kódolása, mégnagyobb védelmet biztosít, a képeken szereplő személyek számára!'>
                  <p>
                    <MdInfo /> Galéria külön kódolása:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.galleryCoding ? (
                    <MdCheck
                      color={
                        bundleData?.galleryCoding !== shopTier?.galleryCoding && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.galleryCoding !== shopTier?.galleryCoding && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A funkció lehetővét teszi, hogy a vásárlók az Iskolaévkönyv felületén végezzék ne csak a rendelés leadását, de még a fizetést is!'>
                  <p>
                    <MdInfo /> Online fizetés:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.onlinePayment ? (
                    <MdCheck
                      color={
                        bundleData?.onlinePayment !== shopTier?.onlinePayment && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.onlinePayment !== shopTier?.onlinePayment && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A funkció lehetővé teszi, hogy a rendszer automatikusan kezelje a rendelések státuszait! Nem lesz többé szükséged a rendelések státuszának manuális módosítására!'>
                  <p>
                    <MdInfo /> Automata státuszkezelés:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.statusAutomation ? (
                    <MdCheck
                      color={
                        bundleData?.statusAutomation !== shopTier?.statusAutomation && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.statusAutomation !== shopTier?.statusAutomation && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='A digitális formátumban rendelt képeket a rendszer automatikusan elküldi a vásárlónak, az általa megadott e-mail címre, a fizetés rendezése után!'>
                  <p>
                    <MdInfo /> Digitális képek automatikus küldése:
                  </p>
                </Tooltip>
                <p>
                  {bundleData?.digitalImageSending ? (
                    <MdCheck
                      color={
                        bundleData?.digitalImageSending !== shopTier?.digitalImageSending && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.digitalImageSending !== shopTier?.digitalImageSending && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség árazási sablonok léterhozására. Az általad létrehozott sablonokat korlátlanul használhatod, az összes galériádon!'>
                  <p>
                    <MdInfo /> Árazási sablon létrehozás:
                  </p>
                </Tooltip>{' '}
                <p>
                  {bundleData?.pricingTemplates ? (
                    <MdCheck
                      color={
                        bundleData?.pricingTemplates !== shopTier?.pricingTemplates && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.pricingTemplates !== shopTier?.pricingTemplates && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
              <div className={styles.detail_item}>
                <Tooltip title='Lehetőség számlakibocsátó szolgáltató beállítására (Számlázz.hu/Billingo). A számlák automatikusan generálódnak és azokat az Iskolaévkönyv rendszere továbbítja a vásárlók felé!'>
                  <p>
                    <MdInfo /> Automata számlázás:
                  </p>
                </Tooltip>{' '}
                <p>
                  {bundleData?.automaticInvoice ? (
                    <MdCheck
                      color={
                        bundleData?.automaticInvoice !== shopTier?.automaticInvoice && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'green'
                      }
                      fontSize={22}
                    />
                  ) : (
                    <MdClose
                      color={
                        bundleData?.automaticInvoice !== shopTier?.automaticInvoice && !!shopTier
                          ? 'var(--primary-background-secondary)'
                          : 'red'
                      }
                      fontSize={22}
                    />
                  )}
                </p>
              </div>
            </div>
            <div className={styles.price_wrapper}>
              <b>Csomag ára:</b>{' '}
              {bundleData.recurringPrice > 0 ? (
                <>
                  <div className={styles.payment}>{bundleData.recurringPrice} Ft/hó</div>
                  <div className={styles.payment}>{bundleData.recurringPriceYearly} Ft/év</div>
                </>
              ) : bundleData.commissionRate > 0 ? (
                <div className={styles.payment}>Részesedés: {bundleData.commissionRate * 100} %</div>
              ) : (
                <>
                  <div className={styles.payment}>0 Ft/hó</div>
                  <div className={styles.payment}>0 Ft/év</div>
                </>
              )}
            </div>

            <div className={styles.button_wrapper}>
              <button className={styles.cancel_button} onClick={() => setOpen(false)}>
                Vissza
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BundleDialog;
