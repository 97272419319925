/** @format */

import React from 'react';
import Center from 'components/General/center/Center';
import CustomAccordion from 'components/Accordion/Accordion';
import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './PhotographerFAQ.module.css';
import { Helmet } from 'react-helmet';

const CustomerFAQ = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className={styles.mainContainer}>
      <Helmet>
        <title>Iskolaévkönyv - Fotós GYIK</title>
        <meta
          name='description'
          content='Ismerje meg az iskolaévkönyv fotózással kapcsolatos gyakran ismételt kérdéseit (GYIK)! Fedezze fel a lehetőségeket, hogyan növelheti ügyfélszámát és láthatóságát a fotós szakmában.'
        />
      </Helmet>
      <section style={{ margin: '3rem 0', width: '100%' }}>
        <Center element='section'>
          <h1 style={{ textAlign: 'center', fontSize: 30 }}>Gyakori Kérdések Fotósoknak</h1>
          <p>
            Összegyűjtöttük a leggyakrabban előforduló kérdéseket, hogy könnyedén megtaláld a választ, és minden
            információ rendelkezésedre álljon.
          </p>
          <Divider />

          <div className={styles.accordionContainer}>
            Az Iskolaévkönyv portált azért hoztuk létre, hogy megkönnyítsük az <b>iskolai és óvodai fotózást</b> a
            Fényképész kollégák és az intézményekben dolgozók számára.
            <br />
            <br />A későbbiekben az egyszerűség kedvéért az alábbi elnevezéseket használjuk:
            <ul className={styles.list}>
              <li>
                <b>Intézmény:</b> Iskola vagy óvoda
              </li>
              <li>
                {' '}
                <b>Csoport:</b> Osztály vagy óvodai csoport
              </li>
              <li>
                {' '}
                <b>Fényképész:</b> A fotókat készítő fényképész kolléga
              </li>
              <li>
                {' '}
                <b>Megrendelő:</b> Szülők
              </li>
            </ul>
            <br />
            <CustomAccordion title={'Hogyan használható a rendszer?'}>
              Az oldal értékesítési céllal történő használatához fotósként regisztráció szükséges. Regisztrálni a
              „Fotósoknak” és a „Fotós bejelentkezés” aloldalak alján található regisztráció gomb megnyomását követő
              regisztrációs űrlap kitöltésével lehetséges.
              <br />
              <br />A regisztrációt követően linket küldünk e-mailben, amire rákattintva aktiválni szükséges a
              felhasználói profilt. Belépést követően lehetőséged van megadni a számlázási adatokat az ügyfeleknek
              történő automata számlázáshoz. Ehhez egy Számlázz.hu vagy egy Billingo fiókra van szükséged. Amint
              megtörtént a regisztráció, a fiók aktiválása és a számlázási beállítások megadása, a rendszer már
              használható is. Az adminisztrációs felületen keresztül van lehetőség az intézmény és azon belül a
              csoportok létrehozására és a csoportokhoz tartozó fotók feltöltésére. Az intézmények mindenképp, a
              csoportok opcionálisan egyedi kódot kaphatnak, tehát a megrendelő csak a saját gyermekének intézményéből
              tud vásárolni. <br />
              <br />A vásárlás folyamata az intézményhez tartozó belépési kód megadásától eltekintve megegyezik egy
              Online vásárlás folyamatával. A megrendelő a kapott kóddal belép az adott intézmény galériájába,
              kiválogatja a képeket és azok formátumát, valamint méretét, kosárba helyezi majd attól függően, hogy a
              fotós milyen csomagot választ leadja a rendelését a fotósnak, amelyet későbbiekben a fotóssal egyeztetett
              módon kifizet részére vagy kártyás fizetéssel (SimplePay) kifizeti. A digitális fotók azonnal
              kiszállításra kerülnek a megrendelő e-mail címére és letölthetőek. <br />A papíralapú, előhívott képek a
              galéria lejárati ideje, vagy nyomtatásra küldése után kerülnek előhívásra és kiszállításra a fényképész
              részére.
              <br />
              <br />
              Profi csomag esetén lehetőséged van megadni a számlázási adatokat az ügyfeleknek történő automata
              számlázáshoz. Ehhez egy Számlázz.hu vagy egy Billingo fiókra van szükséged.
            </CustomAccordion>
            <CustomAccordion title={'Mennyibe kerül az Iskolaévkönyv használata?'}>
              Az Iskolaévkönyvnek több csomagja van, amelyek között található ingyenes csomag, havi illetve éves
              előfizetéses csomag, valamint jutalékos alapon működő csomag is. <br />
              <br />
              Az árak adott időszakban fixen meghatározottak és változásuk minimum 30 nappal előtte közlésre kerül. Ezek
              mértéke mindig az Általános szerződési feltételekben kerül meghatározásra. <br />
              <br />A nyomtatott fényképeknek (amennyiben az Iskolaévkönyv laborját veszi igénybe a Fényképész) van egy
              megszabott minimum ára, ami alatt nem értékesíthető a kép.
              <br />
              <br /> A minimum ár az előhívási díjból, rendszerhasználati díjból és a kártyás fizetés díjából áll. A
              Fényképész bevételének értéke azonnal, transzparensen megjelenítésre kerül, amikor a Fényképész beárazza
              az eladásra kerülő egyes fényképtípusokat. <br />
              Ebben a jutalékban az alábbi szolgáltatások vannak beágyazva:
              <ul className={styles.list}>
                <li>Kártyás fizetés díja, beleértve a SimplePay jutalékot is</li>
                {/* <li>
                                    Kártyás fizetés díja, beleértve a SimplePay
                                    vagy Barion jutalékot is
                                </li> */}
                <li>
                  Opcionális automatikus számlázás. A rendszer automatikus számlát állít ki a Fényképész kolléga
                  Számlázz.hu vagy Billingo fiókjából.
                </li>
                <li>Tárhely használat a feltöltött fotók után – maximális tárolás 90 nap</li>
                <li>Digitális képek értékesítése</li>
                <li>
                  A papír alapú, előhívott képeket szortírozva, megrendelésenként (gyermekenként) csomagolva kapja meg a
                  fényképész. Csak a fotók intézménybe történő kiszállítása marad feladatként.
                </li>
                <li> GDPR megfelelőség</li>
                <li> Utórendelés lehetősége</li>
              </ul>
            </CustomAccordion>
            <CustomAccordion title={'Milyen értékesítési csomagok közül választhatok?'}>
              <p style={{ marginTop: 0 }}>
                Az iskolaévkönyvön jelenleg 4 csomag elérhető, amelyek ára és funkcionalitása eltérő. A választható
                csomagokat és azok részleteit{' '}
                <a
                  className={styles.styledLink}
                  href='/customer-faq'
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/fotosokat-keresunk#csomagok');
                  }}>
                  ide kattintva
                </a>{' '}
                érheted el.
              </p>
            </CustomAccordion>
            <CustomAccordion title={'Milyen fényképek árusítását teszi lehetővé a rendszer?'}>
              Mivel már a legkisebb csomagban is elérhető a saját termék definiálásának lehetősége, így gyakorlatilag az
              oldalon bármilyen kép és ajándéktárgy értékesíthető, viszont fontos figyelembe venni, hogy az általunk
              kiküldött digitális képek és a saját laborunkban előhívott nyomtatott formátumú képek esetén különböző
              díjak kerülhetnek felszámításra, amelyeket a lenti táblázat tartalmaz:
              <br />
              <br />
              <div className={styles.priceTableWrapper}>
                <table className={styles.table}>
                  <thead className={styles.thead}>
                    <td className={styles.rowItem}>Képméret</td>
                    <td className={styles.rowItem}>Kép típusa</td>
                    <td className={styles.rowItem}>Előhívás díja</td>
                    <td className={styles.rowItem}>
                      Rendszerhasználati díj
                      <br />
                      (Profi csomag esetén)
                    </td>
                    <td className={styles.rowItem}>Kártyás fizetés díja</td>
                  </thead>
                  <tbody className={styles.tbody}>
                    <tr>
                      <td className={styles.rowItem}>Eredeti kép</td>
                      <td className={styles.rowItem}>digitális</td>
                      <td className={styles.rowItem}>-</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>1200x1800</td>
                      <td className={styles.rowItem}>digitális</td>
                      <td className={styles.rowItem}>-</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>600x900</td>
                      <td className={styles.rowItem}>digitális</td>
                      <td className={styles.rowItem}>-</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>9x13 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>45Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>10x15 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>55Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>13x18 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>90Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>20x30 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>300Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>30x45 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>600Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>2db 6x9 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>50Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>2db 7x10 cm</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>60Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>4db igazolványkép</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>100Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                    <tr>
                      <td className={styles.rowItem}>9db bélyegkép</td>
                      <td className={styles.rowItem}>előhívott</td>
                      <td className={styles.rowItem}>120Ft</td>
                      <td className={styles.rowItem}>15%</td>
                      <td className={styles.rowItem}>0%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <br />
              2024.12.09-én aktuális áraknak felelnek meg. A rendszerhasználati díj %-ban meghatározott értéke a
              megadott értékesítési árhoz van viszonyítva. <br />
              <br />A képek konverzióját a rendszer automatikusan kezeli, annak megfelelően, hogy a megrendelő milyen
              felbontású képet vásárolt.
              <br />
              <br />A digitális képek kártyás fizetés után automatikusan kiküldésre kerülnek a megrendelő e-mail címére.{' '}
              <br />
              <br />A fényképek árazását a Fényképész egyedileg végezheti el, akár intézményenként és csoportonként is
              eltérő árakat megszabva.
            </CustomAccordion>
            <CustomAccordion title={'Meddig érhetőek el a fotók a portálon?'}>
              Választott csomagtól függően a Fényképésznek lehetősége van beállítani az egyes csoportok képtípusainak a
              rendelési határidejét. Olyan csomagok esetén, amelyeknél nem adható meg lejárati határidő, ott egészen
              addig adhatnak le az ügyfelek rendelést, ameddig a fotós nem inaktiválja a galériát. <br />
              <br />A lejárati időn belül vagy választott csomag függvényében a galéria inaktiválásáig van lehetőség a
              fotók megvásárlására. A lejárt vagy (amennyiben a csomag lehetővé teszi) nyomtatásra küldött galéria, a
              későbbiekben nyomtatott képek rendelésére már nem nyitható vissza, digitális képek rendelésére viszont
              igen, így a megrendelőknek lehetőségük van utórendelés leadására digitális formátumban. <br />
              <br />A papíralapú képeknél az osztály (galéria) lejárati dátuma után kerülnek előhívásra a képek, amiket
              a Fényképész megrendelésenként csomagolva kap kézhez.
              <br />
              <br />
              Az egyes galériákból a képek GDPR szerint, a keletkezéstől számított 90 nap után automatikusan törlésre
              kerülnek.
            </CustomAccordion>
          </div>
          <Divider />
          <br />
          <div>
            A Vásárlóknak szóló <b>Gyakori Kérdések</b> oldalunk{' '}
            <a
              className={styles.styledLink}
              href='/customer-faq'
              onClick={(e) => {
                e.preventDefault();
                history.push('/customer-faq');
              }}>
              ezen a linken
            </a>{' '}
            érhető el.
          </div>
        </Center>
      </section>
    </main>
  );
};

export default CustomerFAQ;
