/** @format */

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useGroupAPI } from 'pages/Group/hooks/useGroupAPI';
import React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import validationSchema from 'validation/GuestLogin/Schemas';
import { errorToast } from 'components/Toast/Toast';
import styles from './GroupCodeField.module.css';
import { useDispatch } from 'react-redux';
import { setShop } from 'redux/slices/ShopSlice';

const GroupCodeField = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const [group, setGroup] = useState();

  const { fetchGroupBySearchKey } = useGroupAPI();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      checkPassword();
    }
  });

  //CHECK HERE IF CART ID'S ACCESSKEY EQUAL TO FORMIK.VALUES.PASSWORD

  const redirectToGroup = (groupRes) => {
    const path = `gallery/${groupRes.id}`;

    history.push(path, {
      accessKey: formik.values.password,
      groupId: groupRes.id
    });
  };

  const checkPassword = async () => {
    await fetchGroupBySearchKey({
      searchKey: formik.values.password
    })
      .then((res) => {
        setGroup(res?.data);
        dispatch(setShop(res?.data?.shopId));
        redirectToGroup(res?.data);
      })
      .catch(() =>
        errorToast(
          'A galéria nem elérhető a megadott keresőkulccsal, ellenőrizd a beírt kulcs helyességét. Ammenyiben mindent rendben találtál, de a galériát továbbra sem sikerül elérni, kérjük vedd fel a kapcsolatot a fotóssal!'
        )
      );
  };

  return (
    <div className={styles.fieldWrapper}>
      <form onSubmit={formik.handleSubmit} className={styles.input}>
        <div className={styles.formContainer}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            type={showPassword ? 'text' : 'password'}
            id='password'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            autoComplete='current-password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            placeholder='Írja be kódját!'
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'var(--primary-text-primary)',
                borderRadius: '20px',
                height: '46px'
              },
              '& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiFormHelperText-root': {
                fontFamily: 'var(--font-secondary), Sans-serif, Arial'
              }
            }}
          />

          <button className={styles.loginButton} type='submit' style={{ zIndex: 1000 }}>
            BELÉPÉS
          </button>
        </div>
      </form>
    </div>
  );
};

export default GroupCodeField;
