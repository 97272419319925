import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React from 'react';
import { MdExpandMore } from 'react-icons/md';
import styles from './Accordion.module.css';

const CustomAccordion = ({ title, children }) => {
  return (
    <Accordion className={styles.container}>
      <AccordionSummary aria-controls='panel1-content' id='panel1-header' expandIcon={<MdExpandMore />}>
        <h3 className={styles.title}>
          <span className={styles.hashtag}>#</span> {title}
        </h3>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
