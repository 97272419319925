import { getApi } from '../axios';

export default {
  async getPublicShopTiers() {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL,
        auth: true
      })
    ).get(`/shop-tiers`);
  }
};
