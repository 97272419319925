import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useGroupAPI } from '../hooks/useGroupAPI';
import Zoom from '@material-ui/core/Zoom';
import styles from './GroupCard.module.css';

const GroupCard = ({ belongsToId, groupName, groupCategory, groupId, imgSrc }) => {
  const history = useHistory();
  const { cover, fetchCover } = useGroupAPI();

  useEffect(() => {
    if (localStorage.getItem('shopId')) {
      fetchCover({
        shopId: localStorage.getItem('shopId'),
        id: groupId
      });
    }
  }, [groupId, localStorage.getItem('shopId')]);

  const calcName = (name) => {
    let displayedName = name;
    if (name.length > 25) {
      displayedName = name.slice(0, 10) + '...' + name.slice(name.length - 10, name.length);
    }
    return displayedName;
  };

  const routeChange = () => {
    localStorage.setItem('parentGroupId', belongsToId.toString());
    history.push(`/gallery/${groupId}`, {
      groupId,
      groupName,
      groupCategory
    });
  };

  return (
    <Zoom in={true} timeout={700} unmountOnExit>
      <div className={styles.cardContainer}>
        <div onClick={routeChange} className={styles.cardWrapper}>
          <div className={styles.imageWrapper}>
            {cover || imgSrc ? (
              <img className={styles.cardImage} src={cover || imgSrc} alt={groupName} />
            ) : (
              <div className={styles.placeholder} />
            )}
            {groupCategory && <span className={styles.categoryBadge}>{groupCategory}</span>}
          </div>
          <div className={styles.cardContent}>
            <Tooltip title={groupName}>
              <Typography className={styles.title}>{calcName(groupName)}</Typography>
            </Tooltip>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

export default GroupCard;
