/** @format */

import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import Zoom from '@material-ui/core/Zoom';
import styles from './Checkout.module.css';
import { useOrderAPI } from './hooks/useOrderAPI';
import BillingForm from './BillingForm/BillingForm';
import AddressForm from './AddressForm/AddressForm';
import Review from './Review/Review';
import { usePaymentAPI } from './hooks/usePaymentAPI';
import { useDeliveryAPI } from './hooks/useDeliveryAPI';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import ManualPaymentDetails from './ManualPaymentDetails/ManualPaymentDetails';
import { getShop } from 'api/queries/UserQueries';
import { useQuery } from '@tanstack/react-query';
// import { setDemo } from 'redux/slices/ShopSlice';
import { formatPrice } from 'utils/Converters';

const Checkout = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  // const dispatch = useDispatch();
  const [deliveryTargets, setDeliveryTargets] = useState();
  const [deliveryTargetObjects, setDeliveryTargetObjects] = useState();
  const { postOrder, loading, submitted, order } = useOrderAPI(() => setActiveStep(3));
  const { paymentMethods } = usePaymentAPI();
  const { getDeliveryTargets, deliveryMethods, fetchItemGroup, itemGroup, schoolGroup, fetchSchoolGroup } =
    useDeliveryAPI();

  const { data: shopData } = useQuery({
    queryKey: ['shopData', shopId],
    queryFn: () => getShop({ shopId }),
    // onSuccess: (data) => {
    //   if (data?.data?.isDemo) {
    //     dispatch(setDemo(true));
    //   } else {
    //     dispatch(setDemo(false));
    //   }
    // },
    enabled: !!shopId
  });

  useEffect(() => {
    getDeliveryTargets(
      localStorage.getItem('shopId'),
      cart,
      setDeliveryTargets,
      setDeliveryTarget,
      setDeliveryTargetObjects
    );
    fetchItemGroup({
      id: cart?.items[0]?.groupId,
      shopId: localStorage.getItem('shopId')
    });
  }, [cart?.items[0]?.groupId]);

  useEffect(() => {
    fetchSchoolGroup({
      id: itemGroup?.belongsTo?.id,
      shopId: localStorage.getItem('shopId')
    });
  }, [itemGroup?.belongsTo?.id]);

  useEffect(() => {
    if (!localStorage.getItem('cartId') || typeof localStorage.getItem('cartId') === 'undefined') {
      history.push('/');
    }
  }, []);

  const [billingData, setBillingData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    emailConfirm: '',
    phone: '',
    companyName: '',
    VATNumber: '',
    GroupVatNumber: '',
    SocialVATNumber: '',
    street: '',
    streetNo: '',
    city: '',
    zip: '',
    country: 'Magyarország',
    isCompany: false
  });

  const [shippingData, setShippingData] = useState({
    deliveryTarget: '',
    submitted: false
  });

  const [deliveryTarget, setDeliveryTarget] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('1');
  const [paymentMethod, setPaymentMethod] = useState('OTP_SIMPLE');

  const steps = !shopData?.data?.shopTier?.onlinePayment
    ? ['Számlázási adatok', 'Szállítás és fizetés', 'Rendelés összegzése', 'Rendelés lezárása']
    : ['Számlázási adatok', 'Szállítás és fizetés', 'Rendelés összegzése'];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getStepContent = (step) => {
    if (!shopData?.data?.shopTier?.onlinePayment) {
      switch (step) {
        case 0:
          return (
            <BillingForm
              steps={steps}
              stepNumber={step}
              handleNext={handleNext}
              handleBack={handleBack}
              isCompany={isCompany}
              setIsCompany={setIsCompany}
              data={billingData}
              setData={setBillingData}
            />
          );
        case 1:
          return (
            <AddressForm
              steps={steps}
              stepNumber={step}
              handleNext={handleNext}
              handleBack={handleBack}
              deliveryTargets={deliveryTargets}
              data={shippingData}
              billingData={billingData}
              setData={setShippingData}
              deliveryTarget={deliveryTarget}
              setDeliveryTarget={setDeliveryTarget}
              deliveryMethod={deliveryMethod}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              paymentMethod={paymentMethod}
              setDeliveryMethod={setDeliveryMethod}
              setPaymentMethod={setPaymentMethod}
              shopData={shopData?.data}
            />
          );
        case 2:
          return (
            <Review
              steps={steps}
              stepNumber={step}
              handleNext={postOrder}
              handleNextPage={() => setActiveStep(activeStep + 1)}
              handleBack={handleBack}
              loading={loading}
              submitted={submitted}
              source={props.datasource}
              deliveryPrice={props.deliveryPrice}
              shippingData={{
                ...billingData.street,
                ...shippingData
              }}
              billingData={billingData}
              isCompany={isCompany}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              deliveryMethod={deliveryMethod}
              paymentMethod={paymentMethod}
              deliveryTarget={deliveryTarget}
              deliverySchool={schoolGroup}
              deliveryTargetObjects={deliveryTargetObjects}
              shopData={shopData?.data}
            />
          );
        case 3:
          return <ManualPaymentDetails order={order} shopData={shopData?.data} />;
        default:
          throw new Error('Unknown step');
      }
    } else {
      switch (step) {
        case 0:
          return (
            <BillingForm
              steps={steps}
              stepNumber={step}
              handleNext={handleNext}
              handleBack={handleBack}
              isCompany={isCompany}
              setIsCompany={setIsCompany}
              data={billingData}
              setData={setBillingData}
            />
          );
        case 1:
          return (
            <AddressForm
              steps={steps}
              stepNumber={step}
              handleNext={handleNext}
              handleBack={handleBack}
              deliveryTargets={deliveryTargets}
              data={shippingData}
              billingData={billingData}
              setData={setShippingData}
              deliveryTarget={deliveryTarget}
              setDeliveryTarget={setDeliveryTarget}
              deliveryMethod={deliveryMethod}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              paymentMethod={paymentMethod}
              setDeliveryMethod={setDeliveryMethod}
              setPaymentMethod={setPaymentMethod}
            />
          );
        case 2:
          return (
            <Review
              steps={steps}
              stepNumber={step}
              handleNext={postOrder}
              handleBack={handleBack}
              loading={loading}
              submitted={submitted}
              source={props.datasource}
              deliveryPrice={props.deliveryPrice}
              shippingData={{
                ...billingData.street,
                ...shippingData
              }}
              billingData={billingData}
              isCompany={isCompany}
              deliveryMethods={deliveryMethods}
              paymentMethods={paymentMethods}
              deliveryMethod={deliveryMethod}
              paymentMethod={paymentMethod}
              deliveryTarget={deliveryTarget}
              deliverySchool={schoolGroup}
              deliveryTargetObjects={deliveryTargetObjects}
              shopData={shopData?.data}
            />
          );
        case 3:
        default:
          throw new Error('Unknown step');
      }
    }
  };

  const history = useHistory();

  const countTotalQuantity = () => {
    var total = 0;
    cart?.items?.forEach((item) => (total += item.quantity));
    return total;
  };

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Megrendelés</title>
        <meta name='description' content='A fényképek megrendeléshez szükséges adatok megadása.' />
      </Helmet>
      <Fragment>
        <CssBaseline />
        <div className={styles.layout}>
          <div className={styles.contentWrapper}>
            <Zoom in={true} timeout={700}>
              <div>
                <div className={styles.header}>
                  <h1 className={styles.headerTitle}>Rendelés</h1>
                </div>
                <div>
                  <Stepper
                    sx={{
                      '& .MuiStepLabel-labelContainer': {
                        marginTop: '0 !important'
                      },
                      '& .MuiTypography-root': {
                        fontFamily: 'var(--font-main), Sans-serif, Arial !important'
                      },
                      '& .MuiStepLabel-root': {
                        padding: '0 !important'
                      },
                      '& .MuiStepLabel-label': {
                        fontFamily: 'var(--font-main), Sans-serif, Arial !important',
                        margin: '0 !important'
                      },
                      '& .MuiStepIcon-root': {
                        color: 'var(--primary-background-secondary) !important'
                      },
                      '& .MuiStepConnector-lineVertical': {
                        marginTop: '5px !important',
                        marginBottom: '5px !important',
                        marginLeft: '24px !important'
                      },
                      '& .MuiStepIcon-completed': {
                        color: 'var(--primary-background-secondary) !important'
                      },
                      '& .MuiStepIcon-active': {
                        color: 'var(--primary-background-secondary) !important'
                      },
                      '& .Mui-disabled .MuiStepIcon-root': {
                        color: '#E5E7EB !important'
                      }
                    }}
                    activeStep={activeStep}
                    orientation='vertical'
                    className={styles.stepper}>
                    {steps.map((label, index) => (
                      <Step key={label} className={styles.stepContainer}>
                        <StepLabel>
                          <div className={styles.dialogTitle}>{label}</div>
                        </StepLabel>
                        <StepContent style={{ borderLeft: 'unset !important' }}>{getStepContent(index)}</StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </Zoom>
            <div className={styles.summaryWrapper}>
              <h2 className={styles.summaryTitle}>Összegzés</h2>
              <div className={styles.summaryItem}>
                <span className='label'>{countTotalQuantity()} termék</span>
                <span className='value'>{formatPrice(cart?.totalPrice?.grossAmount?.toFixed(0))} HUF</span>
              </div>

              <div className={styles.summaryItem}>
                <span className='label'>Teljes összeg</span>
                <span className='value'>{formatPrice(cart?.totalPrice?.grossAmount?.toFixed(0))} HUF</span>
              </div>
              <button className={styles.checkoutButton} onClick={() => history.push('/cart')}>
                Vissza a kosárhoz
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    </main>
  );
};

export default Checkout;
