/** @format */

import { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import styles from './Review.module.css';
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { successToast, errorToast } from 'components/Toast/Toast';
import { CardPlaceholder } from 'components/Group/GroupCard/styles';
import { ItemDetails } from 'pages/Cart/CartItem/styles';
import { formatPrice } from 'utils/Converters';
import jsonp from 'jsonp';

const Review = (props) => {
  const cart = useSelector((state) => state.nonPersistedReducers.cartSliceReducer.cart);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const { getDocuments, downloadDocuments, documents } = useDocumentsApi();

  const handleOrder = async () => {
    if (newsAccept) {
      let values = getOrderJson().customerContact;
      const url = `https://iskolaevkonyv.us22.list-manage.com/subscribe/post-json?u=c270787a60e53d5f28392465f&amp;id=${window._env_.REACT_APP_MAILCHIMP_ID}&amp;f_id=${window._env_.REACT_APP_MAILCHIMP_FORMID}`;
      jsonp(
        `${url}&EMAIL=${values?.email}&FNAME=${values?.firstName}&LNAME=${
          values?.lastName
        }&CATEGORY=${'common'}&EBOOK=false`,
        { param: 'c' },
        (_, data) => {
          const { result } = data;
          if (result == 'success') {
            successToast('Sikeres feliratkozás!');
          }
          if (result == 'error') {
            errorToast('Sikertelen feliratkozás, kérjük próbáld újra!');
          }
        }
      );
    }

    await props.handleNext({
      order: getOrderJson(),
      shop: props?.shopData
    });
  };

  useEffect(() => {
    getDocuments();
  }, [shopId]);

  const getOrderJson = () => {
    const orderjson = {
      cartId: cart?.id,
      vendorKey: 1,
      billingInfo: {
        customerName: `${props.billingData?.firstName} ${props.billingData?.lastName}`,
        companyName: props.billingData?.companyName,
        vatNumber: props.billingData?.VATNumber,
        address: {
          country: props.billingData?.country,
          zipCode: props.billingData?.zip,
          city: props.billingData?.city,
          street: props.billingData?.street,
          streetNo: ''
        }
      },
      address: {
        country: props.billingData?.country,
        zipCode: props.billingData?.zip,
        city: props.billingData?.city,
        street: props.billingData?.street,
        streetNo: ''
      },
      customerContact: {
        firstName: props.billingData?.firstName,
        lastName: props.billingData?.lastName,
        email: props.billingData?.email,
        phone: props.billingData.phone.startsWith('06', 0) ? props.billingData.phone : `+36${props.billingData?.phone}`
      },
      description: '',
      // FOR DELIVERY
      // deliveryMethod: props?.deliveryMethod,
      deliveryTarget: {
        deliveryTarget: props?.deliveryTarget
      },
      invoiceVendorType: 'SZAMLAZZ_HU',
      paymentMethod: props?.paymentMethod,
      status: 'PENDING',
      language: 'HU'
    };

    return orderjson;
  };

  const [newsAccept, setNewsAccept] = useState(false);
  const [tocAccepted, setTocAccepted] = useState(false);
  const [aszfAccepted, setAszfAccepted] = useState(false);

  // const checkDocumentAcceptance = () => {
  //   if (documents?.find((d) => d.accepted === true)) {
  //     setAszfAccepted(true);
  //   } else {
  //     setAszfAccepted(false);
  //   }
  // };

  // useEffect(() => {
  //   checkDocumentAcceptance();
  // }, [documents]);

  const handleBack = () => {
    // Scroll to top before going back
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // Small delay to allow smooth scroll before navigation
    setTimeout(() => {
      props.handleBack();
    }, 100);
  };

  return (
    <Fragment>
      <div className={styles.dataGrid}>
        <div className={styles.dataBlock}>
          <h3 className={styles.dataTitle}>Kapcsolattartói Adatok</h3>
          <div className={styles.dataContent}>
            <p>{`${props.billingData.firstName} ${props.billingData.lastName}`}</p>
            <p>{props.billingData.email}</p>
            <p>+36 {props.billingData.phone}</p>
          </div>
        </div>

        <div className={styles.dataBlock}>
          <h3 className={styles.dataTitle}>Számlázási Adatok</h3>
          <div className={styles.dataContent}>
            <p>{`${props.billingData.firstName} ${props.billingData.lastName}`}</p>
            <p>{`${props.billingData.country}, ${props.billingData.zip}, ${props.billingData.city}`}</p>
            <p>{props.billingData.street}</p>
          </div>
        </div>

        <div className={styles.dataBlock}>
          <h3 className={styles.dataTitle}>Szállítási Adatok</h3>
          <div className={styles.dataContent}>
            <p>{props.deliveryTarget}</p>
          </div>
        </div>

        <div className={styles.dataBlock}>
          <h3 className={styles.dataTitle}>Fizetés módja</h3>
          <div className={styles.dataContent}>
            <div className={styles.paymentMethod}>
              {props.shopData?.shopTier?.onlinePayment ? (
                <>
                  <span>{props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name}</span>
                  <a
                    href={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].url}
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      className={styles.methodLogoImage}
                      src={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].logo}
                      alt={props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name}
                    />
                  </a>
                </>
              ) : (
                <span>Nincs online fizetésre lehetőség</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <List style={{ marginTop: '1rem' }}>
        {cart?.items?.map((item) => (
          <ListItem className={styles.listItem} key={item.id}>
            <div className={styles.imageWrapper}>
              {item.thumbnailURL ? (
                <img alt={item.name} src={item.thumbnailURL} width='90px' />
              ) : (
                <div style={{ width: '90px', height: '90px', position: 'relative' }}>
                  <CardPlaceholder />
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Typography className={styles.listItemText} variant='body2'>
                {item?.modifiersText?.includes('keretezett képek')
                  ? item?.modifiersText?.split('keretezett képek')?.splice(1)?.join(' ')
                  : item?.modifiersText?.split(' ')?.splice(1)?.join(' ')}
              </Typography>
              <Typography className={styles.listItemText} variant='body2'>
                {item.quantity + ' db'}
              </Typography>
              <ItemDetails className={styles.typographyPrice1}>
                {formatPrice(item.unitPrice.grossAmount.toFixed(0))} HUF
              </ItemDetails>
            </div>

            <ItemDetails className={styles.typographyPrice2}>
              {formatPrice(item.unitPrice.grossAmount.toFixed(0))} HUF
            </ItemDetails>
          </ListItem>
        ))}
        <ListItem className={styles.listItem}>
          <Typography variant='subtitle1' className={styles.totalText}>
            Teljes összeg
          </Typography>
          <ItemDetails style={{ marginLeft: 'auto' }}>
            {formatPrice(cart.totalPrice.grossAmount.toFixed(0))} HUF
          </ItemDetails>
        </ListItem>
      </List>

      <Grid>
        <div style={{ marginTop: '0.5rem' }}>
          <div>
            Szeretnék a továbbiakban is értesülni a rendszert érintő újdonságokról, iskolai fotózással kapcsolatos
            hírekről.
          </div>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setNewsAccept(e.target.checked);
                }}
                error={!newsAccept}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={styles.data}>
                <p>Feliratkozom a hírlevélre</p>
              </div>
            }
          />
        </div>
        {props.shopData?.shopTier?.onlinePayment && (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setTocAccepted(e.target.checked);
                }}
                error={!tocAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={styles.data}>
                <p>
                  A{' '}
                  {/* {
                                    props?.paymentMethods?.filter(
                                        (method) =>
                                            method.key === props.paymentMethod
                                    )[0].name
                                }{" "} */}
                  SimplePay{' '}
                  <a
                    target={'_blank'}
                    rel='noreferrer'
                    href={
                      props.paymentMethod === 'BARION'
                        ? 'https://www.barion.com/hu/altalanos-szerzodesi-feltetelek/'
                        : 'https://simplepay.hu/wp-content/uploads/2021/09/SimplePay_b2c_aff_hun_eng_20211001.pdf'
                    }>
                    Általános Felhasználási Feltételeit
                  </a>{' '}
                  elfogadom.
                </p>
              </div>
            }
          />
        )}

        {!!documents?.find((d) => d?.name?.includes('ajándék')) ? (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setAszfAccepted(!aszfAccepted);
                }}
                error={!aszfAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={styles.data}>
                <p>
                  Ajándék tárgyak vásárlása esetén az alábbi
                  {documents
                    ?.filter((d) => d?.name?.includes('ajándék'))
                    ?.map((d) => {
                      return (
                        <div>
                          <a
                            href='/'
                            style={{
                              textDecoration: 'underline',
                              color: 'blue'
                            }}
                            rel='noreferrer'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadDocuments(d.id, d.name);
                            }}>
                            {d.name}{' '}
                          </a>
                          {d.id === 1}
                        </div>
                      );
                    })}
                  dokumentumokban foglaltakat elfogadom. <br />
                  Nyomtatott és digitális képek vásárlása esetén az alábbi
                  {documents
                    ?.filter((d) => !d?.name?.includes('ajándék'))
                    ?.map((d) => {
                      return (
                        <div>
                          <a
                            href='/'
                            style={{
                              textDecoration: 'underline',
                              color: 'blue'
                            }}
                            rel='noreferrer'
                            onClick={(event) => {
                              event.preventDefault();
                              downloadDocuments(d.id, d.name);
                            }}>
                            {d.name}{' '}
                          </a>
                          {d.id === 1}
                        </div>
                      );
                    })}
                  dokumentumokban foglaltakat elfogadom.
                </p>
              </div>
            }
          />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                name='submitted'
                id='submitted'
                required
                onChange={(e) => {
                  setAszfAccepted(!aszfAccepted);
                }}
                error={!aszfAccepted}
              />
            }
            label={
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
                className={styles.data}>
                <span>
                  Az alábbi
                  {documents?.map((d, idx) => {
                    return (
                      <div key={idx}>
                        <a
                          href='/'
                          style={{
                            textDecoration: 'underline',
                            color: 'blue'
                          }}
                          rel='noreferrer'
                          onClick={(event) => {
                            event.preventDefault();
                            downloadDocuments(d.id, d.name);
                          }}>
                          {d.name}{' '}
                        </a>
                        {d.id === 1}
                      </div>
                    );
                  })}
                  dokumentumokban foglaltakat elfogadom.
                </span>
              </div>
            }
          />
        )}
      </Grid>
      {tocAccepted && aszfAccepted && props.shopData?.shopTier?.onlinePayment && (
        <Grid>
          <p style={{ color: 'var(--primary-error)' }}>
            <b>Figyelmeztetés!</b> A "Fizetés" gombra kattintva a rendszer át fog irányítani a{' '}
            {props?.paymentMethods?.filter((method) => method.key === props.paymentMethod)[0].name} oldalára. Amennyiben
            ez mégsem történne meg, az általad megadott email címre kiküldünk egy a fizetéshez szükséges linket
            tartalmazó levelet, amely segítségével végrehajthatod a fizetést. Link hiányában kérjük, hogy próbáld meg
            újra leadni a rendelésed.
          </p>
        </Grid>
      )}

      <div className={styles.navigationButtons}>
        {props.stepNumber !== 0 && (
          <Button
            style={{
              border: '1px solid rgba(0,0,0,0.2)',
              margin: '30px 0 0 0'
            }}
            onClick={handleBack}>
            Vissza
          </Button>
        )}
        <Button
          className={styles.button}
          disabled={
            !props.shopData?.shopTier?.onlinePayment
              ? !aszfAccepted || props.submitted
              : !tocAccepted || !aszfAccepted || props.submitted
          }
          variant='contained'
          color='primary'
          onClick={handleOrder}
          type='submit'>
          {!props.shopData?.shopTier?.onlinePayment ? 'Rendelés leadása' : 'Fizetés'}{' '}
          <label hidden={!props.submitted && !props.loading}>
            <CircularProgress size={24} style={{ color: 'black !important', marginLeft: '0.5rem' }} />
          </label>
        </Button>
      </div>
    </Fragment>
  );
};

export default Review;
