import { Fragment, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import styles from './BillingForm.module.css';
import { Button } from '@material-ui/core';
import CompanyForm from './CompanyForm/CompanyForm';
import PersonalForm from './PersonalForm/PersonalForm';
import { TabContext, TabPanel } from '@material-ui/lab';
import { InputAdornment } from '@material-ui/core';
import { useFormik } from 'formik';
import { TextField } from '@mui/material';
import { BillingSchema } from 'validation/Checkout/Schemas';

const BillingForm = (props) => {
  const [value] = useState('1');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: props.data.firstName,
      lastName: props.data.lastName,
      email: props.data.email,
      emailConfirm: props.data.emailConfirm,
      phone: props.data.phone,
      companyName: props.data.companyName,
      VATNumber: props.data.VATNumber,
      GroupVatNumber: props.data.GroupVatNumber,
      SocialVATNumber: props.data.SocialVATNumber,
      street: props.data.street,
      city: props.data.city,
      zip: props.data.zip,
      country: props.data.country,
      isCompany: props.data.isCompany
    },
    validationSchema: BillingSchema,
    onSubmit: (values) => {
      props.setData(values);
      props.handleNext();
    }
  });

  return (
    <Fragment>
      <div>
        Add meg a számlázáshoz szükséges információkat, hogy a vásárlásról kiállított számlát megfelelően el tudjuk
        készíteni.
      </div>
      <form onSubmit={formik.handleSubmit} style={{ marginTop: '1rem' }}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  '& .MuiFormLabel-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                    '&.Mui-focused': {
                      color: 'var(--primary-background-primary) !important'
                    }
                  },
                  '& .MuiInputBase-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--primary-background-primary) !important'
                    }
                  }
                }}
                variant='outlined'
                required
                id='firstName'
                name='firstName'
                label='Vezetéknév'
                fullWidth
                autoComplete='family-name'
                value={formik.values.firstName}
                autoFocus
                className={styles.input}
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setData({
                    ...props.data,
                    firstName: e.target.value
                  });
                }}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  '& .MuiFormLabel-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                    '&.Mui-focused': {
                      color: 'var(--primary-background-primary) !important'
                    }
                  },
                  '& .MuiInputBase-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--primary-background-primary) !important'
                    }
                  }
                }}
                variant='outlined'
                required
                id='lastName'
                name='lastName'
                label='Keresztnév'
                fullWidth
                autoComplete='given-name'
                value={formik.values.lastName}
                className={styles.input}
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setData({
                    ...props.data,
                    lastName: e.target.value
                  });
                }}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  '& .MuiFormLabel-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                    '&.Mui-focused': {
                      color: 'var(--primary-background-primary) !important'
                    }
                  },
                  '& .MuiInputBase-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--primary-background-primary) !important'
                    }
                  }
                }}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email cím'
                name='email'
                autoComplete='email'
                value={formik.values.email}
                className={styles.input}
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setData({
                    ...props.data,
                    email: e.target.value
                  });
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  '& .MuiFormLabel-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                    '&.Mui-focused': {
                      color: 'var(--primary-background-primary) !important'
                    }
                  },
                  '& .MuiInputBase-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--primary-background-primary) !important'
                    }
                  }
                }}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email cím megerősítése'
                name='email'
                autoComplete='email'
                value={formik.values.emailConfirm}
                className={styles.input}
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setData({
                    ...props.data,
                    emailConfirm: e.target.value
                  });
                }}
                error={formik.touched.emailConfirm && Boolean(formik.errors.emailConfirm)}
                helperText={formik.touched.emailConfirm && formik.errors.emailConfirm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{
                  '& .MuiFormLabel-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                    '&.Mui-focused': {
                      color: 'var(--primary-background-primary) !important'
                    }
                  },
                  '& .MuiInputBase-root': {
                    fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--primary-background-primary) !important'
                    }
                  }
                }}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='phone'
                label='Telefonszám'
                name='phone'
                autoComplete='phone'
                className={styles.input}
                value={formik.values.phone}
                onChange={(e) => {
                  formik.handleChange(e);
                  props.setData({
                    ...props.data,
                    phone: e.target.value
                  });
                }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>+36</InputAdornment>,
                  placeholder: '301234567'
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <TabContext value={value}>
              <TabPanel style={{ width: '100%', padding: '1rem' }} value='1'>
                {<PersonalForm data={props.data} setData={props.setData} formik={formik} />}
              </TabPanel>
              <TabPanel style={{ width: '100%' }} value='2'>
                {<CompanyForm data={props.data} setData={props.setData} formik={formik} />}
              </TabPanel>
            </TabContext>
          </Grid>

          <div className={styles.navigationButtons}>
            <Button className={styles.button} variant='contained' color='primary' type='submit'>
              Tovább
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default BillingForm;
