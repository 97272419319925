import { Button, Checkbox, Dialog, FormControlLabel, Slide } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import styles from './ShopNotificationDialog.module.css';

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ShopNotificationDialog = ({ open, setOpen }) => {
  // const { notification, fetchNotification } = useNotificationAPI();
  const baseDocuments = useSelector((state) => state.nonPersistedReducers?.documentSliceReducer?.documents);
  const { downloadBaseDocuments } = useDocumentsApi();

  const [aszfAccepted, setAszfAccepted] = useState(false);

  const notifications = [
    {
      title: 'Tisztelt Vásárlók!',
      mainDesc:
        'Oldalunkról történő vásárláshoz, el kell fogadnotok Adatkezelési nyilatkozatunkat, illetve Általános szerződési feltételeinket!',
      secondaryDesc: 'Megértéseteket köszönjük!',
      btnText: 'Megértettem',
      skippable: true
    },
    {
      title: 'Tisztelt Vásárlók!',
      mainDesc:
        'Nyomtatott képek rendelésére, azok rendelési határidejéig van lehetőség. Ezután már csak digitális formátumban vásárolhatók meg a képek, a digitális képek lejárati határidejéig.',
      secondaryDesc: 'Köszönjük!',
      btnText: 'Megértettem',
      skippable: true
    }
  ];

  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        className={styles.dialog}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '1000px',
            padding: '2rem',
            margin: '1.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: 20,
            background: 'var(--primary-text-primary)'
          }
        }}
        onClick={(e) => e.stopPropagation()}
        onClose={() => {}}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <>
          <h1 className={styles.title}>{notifications[activeStep]?.title}</h1>
          <h1 className={styles.description}>{notifications[activeStep]?.mainDesc}</h1>
          <h1 className={styles.description}>{notifications[activeStep]?.secondaryDesc}</h1>
          {activeStep === 0 && (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  name='submitted'
                  id='submitted'
                  required
                  checked={aszfAccepted}
                  onChange={(e) => {
                    setAszfAccepted(e.target.checked);
                  }}
                />
              }
              label={
                <p className={styles.data}>
                  Az alábbi &nbsp;
                  {baseDocuments?.map((el, idx) => {
                    return (
                      <span key={idx}>
                        <a
                          href='/'
                          onClick={(event) => {
                            event.preventDefault();
                            downloadBaseDocuments(el.id, el.name);
                          }}>
                          {el.name}
                        </a>
                        {idx !== baseDocuments.length - 1 && ' és az '}
                      </span>
                    );
                  })}
                  &nbsp;dokumentumokban foglaltakat elfogadom.
                </p>
              }
            />
          )}
        </>
        {notifications?.length > 1 && (
          <div className={styles.stepper}>
            {notifications?.map((notification, idx) => {
              return (
                <div
                  className={styles.stepIndicator}
                  key={idx}
                  style={{
                    background: activeStep === idx ? 'var(--primary-background-primary)' : 'lightgrey'
                  }}
                  onClick={() => setActiveStep(idx)}
                />
              );
            })}
          </div>
        )}
        <div className={styles.buttonsWrapper}>
          {notifications?.length > 1 && (
            <Button
              disabled={activeStep === 0}
              className={styles.backButton}
              style={{
                background: activeStep !== 0 ? 'var(--primary-background-primary)' : 'lightgrey'
              }}
              onClick={() => setActiveStep(activeStep - 1)}>
              Vissza
            </Button>
          )}
          {notifications[activeStep]?.skippable ? (
            <Button
              className={styles.button}
              disabled={!aszfAccepted}
              onClick={() => {
                if (notifications[activeStep]?.skippable) {
                  activeStep === notifications?.length - 1 ? setOpen(false) : setActiveStep(activeStep + 1);
                }
              }}>
              {activeStep === notifications?.length - 1 ? notifications[activeStep]?.btnText : 'Tovább'}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default ShopNotificationDialog;
