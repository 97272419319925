/** @format */

import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

export const usePaymentAPI = () => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  // const isDemo = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.isDemo);

  useEffect(() => {
    // if (isDemo) {
    //   setPaymentMethods([
    //     {
    //       key: 'DEMO_OTP_SIMPLE',
    //       name: 'SimplePay(Demo)',
    //       logo: 'https://kreativ-website.hu/asset/media/simplepay-bankkartyas-fizetes.png',
    //       url: 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'
    //     }
    //   ]);
    // } else {
    setPaymentMethods([
      {
        key: 'OTP_SIMPLE',
        name: 'SimplePay kártyás fizetés/átutalás',
        logo: 'https://kreativ-website.hu/asset/media/simplepay-bankkartyas-fizetes.png',
        url: 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'
      }
      // {
      //   key: "BARION",
      //   name: "Barion",
      //   logo: barion_logo,
      // },
      // {
      //   key: "TRANSFER",
      //   name: "Átutalás",
      //   logo: transfer_logo,
      // },
    ]);
    // }
  }, []);

  return { paymentMethods };
};
