import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PostalCodeFields from 'components/PostalCodeFields/PostalCodeFields';

const CompanyForm = (props) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            required
            id='companyName'
            label='Cég'
            name='companyName'
            autoComplete='companyName'
            value={props.formik.values.companyName}
            InputProps={{
              style: {
                fontFamily: `var(--font-main), Sans-serif, Arial`
              }
            }}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({
                ...props.data,
                companyName: e.target.value
              });
            }}
            error={props.formik.touched.companyName && Boolean(props.formik.errors.companyName)}
            helperText={props.formik.touched.companyName && props.formik.errors.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='VATNumber'
            label='Adószám'
            name='VATNumber'
            autoComplete='VATNumber'
            value={props.formik.values.VATNumber}
            InputProps={{
              style: {
                fontFamily: `var(--font-main), Sans-serif, Arial`
              }
            }}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, VATNumber: e.target.value });
            }}
            error={props.formik.touched.VATNumber && Boolean(props.formik.errors.VATNumber)}
            helperText={props.formik.touched.VATNumber && props.formik.errors.VATNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            id='GroupVATNumber'
            label='Csoportos Adószám'
            name='GroupVATNumber'
            autoComplete='GroupVATNumber'
            value={props.formik.values.GroupVATNumber}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, GroupVATNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            id='SocialVATNumber'
            label='Közösségi Adószám'
            name='SocialVATNumber'
            autoComplete='SocialVATNumber'
            value={props.formik.values.SocialVATNumber}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, SocialVATNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            required
            id='country'
            name='country'
            label='Ország'
            fullWidth
            autoComplete='shipping country'
            value={props.formik.values.country}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, country: e.target.value });
            }}
            error={props.formik.touched.country && Boolean(props.formik.errors.country)}
            helperText={props.formik.touched.country && props.formik.errors.country}
          />
        </Grid>
        <PostalCodeFields data={props.data} setData={props.setData} formik={props.formik} />
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            required
            id='address'
            name='address'
            label='Utca, házszám'
            fullWidth
            autoComplete='shipping address-line1'
            value={props.formik.values.address}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, address: e.target.value });
            }}
            error={props.formik.touched.address && Boolean(props.formik.errors.address)}
            helperText={props.formik.touched.address && props.formik.errors.address}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant='outlined'
            required
            id='streetNo'
            name='streetNo'
            label='Házszám'
            fullWidth
            autoComplete='shipping address-line2'
            value={props.formik.values.streetNo}
            style={{ marginBottom: '100px' }}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, streetNo: e.target.value });
            }}
            error={props.formik.touched.streetNo && Boolean(props.formik.errors.streetNo)}
            helperText={props.formik.touched.streetNo && props.formik.errors.streetNo}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default CompanyForm;
