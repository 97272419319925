import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import SIZES from 'constants/sizes';
import { IconButton } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  img: {
    padding: '1rem',
    maxWidth: '200px',
    height: '200px',
    [theme.breakpoints.up('md')]: {
      padding: '0.5rem',
      maxWidth: '100px',
      height: '90px'
    }
  },
  itemName: {
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '1rem',
    width: '15ch',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  itemDataOuter: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  itemData: {
    color: theme.palette.primary.backgroundPrimary,
    fontWeight: 400,
    textAlign: 'center',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    margin: '15px'
  },
  itemCount: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '1.25rem'
  },
  deleteBtn: {
    color: theme.palette.primary.backgroundPrimary,
    position: 'absolute',
    top: '0',
    right: '0',
    '&:hover, :focus': {
      color: theme.palette.primary.backgroundPrimary
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  }
}));

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 120px minmax(0, 1fr);
  gap: 24px;
  padding: 16px 0;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;
  align-items: stretch;

  @media (max-width: 768px) {
    grid-template-columns: 100px minmax(0, 1fr);
    gap: 16px;
  }
`;

export const ImageWrapper = styled.div`
  width: 120px;
  height: 120px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
  overflow: hidden;
  width: 100%;
`;

export const ItemName = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: #4b5563;
    }
  }
`;

export const ItemDetails = styled.div`
  display: flex;
  gap: 8px;
  color: #6b7280;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  flex: 1;
`;

export const DeleteButton = styled(IconButton)`
  padding: 8px;
  color: #6b7280;
  border-radius: 4px;

  &:hover {
    color: #111827;
    background: #f3f4f6;
  }

  svg {
    font-size: 18px;
  }
`;

export const StockStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #059669;
  font-size: 14px;
  margin-top: auto;
  padding-top: 8px;
`;
