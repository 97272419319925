import React from 'react';
import styles from './MethodChooser.module.css';
import { Radio, RadioGroup } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

const MethodChooser = ({
  title = '',
  methods = [],
  selectedMethod = null,
  onMethodChange = () => {},
  priceFormatter = (price) => `${price?.toFixed(0) || 0} HUF`,
  showDescription = true,
  showPrice = true
}) => {
  return (
    <div className={styles.chooserCard}>
      {title && <h2 className={styles.chooserTitle}>{title}</h2>}
      <div className={styles.chooserWrapper}>
        <RadioGroup value={selectedMethod || ''} onChange={(e) => onMethodChange(e.target.value)}>
          {methods.map((method) => (
            <div
              className={selectedMethod === method?.id ? styles.selectedMethod : styles.methodCard}
              key={method?.id}
              selected={selectedMethod === method?.id}
              onClick={() => onMethodChange(method?.id)}>
              <div className={styles.methodContent}>
                <div className={styles.methodHeader}>
                  <div>
                    <div className={styles.methodName}>{method?.name}</div>
                    {showDescription && method?.description && (
                      <div className={styles.methodDescription}>{method.description}</div>
                    )}
                  </div>
                  {selectedMethod === method?.id ? (
                    <CheckIcon className={styles.checkIcon} />
                  ) : (
                    <Radio
                      checked={selectedMethod === method?.id}
                      value={method?.id}
                      name='method-radio'
                      color='primary'
                      size='small'
                      sx={{
                        padding: 0,
                        margin: '-2px',
                        color: selectedMethod === method?.id ? '#FFB800' : '#D1D5DB',
                      }}
                    />
                  )}
                </div>
                {showPrice && method?.price && <div className={styles.price}>{priceFormatter(method.price)}</div>}
              </div>
            </div>
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

export default MethodChooser;
