import Grid from '@material-ui/core/Grid';
import { TextField } from '@mui/material';
import styles from './PersonalForm.module.css';
import PostalCodeFields from 'components/PostalCodeFields/PostalCodeFields';

const PersonalForm = (props) => {
  return (
    <>
      <Grid container spacing={3}>
        <PostalCodeFields data={props.data} setData={props.setData} formik={props.formik} />
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              '& .MuiFormLabel-root': {
                fontFamily: 'var(--font-secondary), Sans-serif, Arial !important',
                '&.Mui-focused': {
                  color: 'var(--primary-background-primary) !important'
                }
              },
              '& .MuiInputBase-root': {
                fontFamily: 'var(--font-secondary), Sans-serif, Arial !important'
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'var(--primary-background-primary) !important'
                }
              }
            }}
            variant='outlined'
            required
            id='street'
            name='street'
            label='Utca, házszám'
            disabled={props.data.submitted}
            fullWidth
            autoComplete='shipping address-line1'
            value={props.formik.values.street}
            className={styles.input}
            onChange={(e) => {
              props.formik.handleChange(e);
              props.setData({ ...props.data, street: e.target.value });
            }}
            error={props.formik.touched.address && Boolean(props.formik.errors.address)}
            helperText={props.formik.touched.address && props.formik.errors.address}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default PersonalForm;
