import React, { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from 'react-router-dom';
import styles from './PaymentDetails.module.css';
import { useParams } from 'react-router-dom';
import { usePaymentAPI } from './hooks/usePaymentAPI';
import Grow from '@material-ui/core/Grow';
import { Helmet } from 'react-helmet';

export const PaymentDetails = () => {
  const { search } = useLocation();
  const { checkoutId } = useParams();

  const { getExternalCheckoutId, externalId, externalOk, externalIdLoading, paymentDetails } = usePaymentAPI();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const s = encodeURIComponent(params.get('s'));
    const r = params.get('r');
    getExternalCheckoutId(checkoutId, { r: r, s: s }, localStorage.getItem('shopId'));
  }, [checkoutId]);

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Fizetés</title>
        <meta name='description' content='A fizetéssel/megrendeléssel kapcsolatos információkat itt találod.' />
      </Helmet>
      <div className={styles.paymentDetailsWrapper}>
        {externalIdLoading ? (
          <div className={styles.paymentDetailsCard}>
            <div className={styles.loadingMessage}>
              <CircularProgress style={{ width: '70px', height: '70px' }} />
              <h1>Fizetés folyamatban, kérjük ne navigálj el az oldalról</h1>
            </div>
          </div>
        ) : (
          <Grow in={true} timeout={700} unmountOnExit>
            <div className={styles.paymentDetailsCard}>
              {/* <CardBack />
              <CardBack2 /> */}
              {externalOk && (
                <>
                  <h1 className={styles.title}>
                    <div className={styles.iconWrapper}>
                      <svg className={styles.successCheck} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
                        <circle className={styles.successCheckCircle} cx='26' cy='26' r='25' fill='none' />
                        <path className={styles.succcesCheckCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                      </svg>
                    </div>
                    <h1 className={styles.cardTitle}>{`Fizetésed sikeres volt.`}</h1>
                  </h1>
                  <div className={styles.paymentDetailsBody}>
                    <h1 className={styles.detailsTitle}>Köszönjük megrendelésed!</h1>
                    <p className={styles.detailsMessage}>
                      Rendelésed részleteiről email üzenetet küldtünk, az általad megadott email címre.
                    </p>
                    <p className={styles.IdText}>{`Sikeres tranzakció.`}</p>
                    <p className={styles.IdText}>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</p>
                    <p className={styles.IdText}>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </p>
                    <a className={styles.homeButton} href='/'>
                      Vissza a főoldalra
                    </a>
                  </div>
                </>
              )}
              {paymentDetails?.status === 'REJECTED' && (
                <>
                  <h1 className={styles.title}>
                    <div className={styles.failIconWrapper}>
                      <CloseIcon className={styles.failIcon} />
                    </div>
                    <h1 className={styles.cardTitle}>{`Fizetés sikertelen.`}</h1>
                  </h1>
                  <div className={styles.failedBody}>
                    <p className={styles.detailsMessage}>
                      Kérjük, ellenőrizd a tranzakció során megadott adatok helyességét. Amennyiben minden adatot
                      helyesen adtál meg, a visszautasítás okának kivizsgálása kapcsán kérjük, szíveskedj kapcsolatba
                      lépni kártyakibocsátó bankoddal.
                    </p>
                    <p className={styles.IdText}>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</p>
                    <p className={styles.IdText}>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </p>
                    <a className={styles.homeButton} href='/'>
                      Vissza a főoldalra
                    </a>
                  </div>
                </>
              )}
              {paymentDetails?.status === 'CANCELLED' && (
                <>
                  <h1 className={styles.title}>
                    <div className={styles.failIconWrapper}>
                      <CloseIcon className={styles.failIcon} />
                    </div>
                    <h1 className={styles.cardTitle}>{`Fizetés megszakítva.`}</h1>
                  </h1>
                  <div className={styles.failedBody}>
                    <p className={styles.IdText}>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</p>
                    <p className={styles.IdText}>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </p>
                    <a className={styles.homeButton} href='/'>
                      Vissza a főoldalra
                    </a>
                  </div>
                </>
              )}
              {paymentDetails?.status === 'TIMEOUT' && (
                <>
                  <h1 className={styles.title}>
                    <div className={styles.failIconWrapper}>
                      <CloseIcon className={styles.failIcon} />
                    </div>
                    <h1 className={styles.cardTitle}>{`Időtúllépés.`}</h1>
                  </h1>
                  <div className={styles.failedBody}>
                    <p className={styles.detailsMessage}>Időtúllépés miatt fizetésed sikertelen.</p>
                    <p className={styles.IdText}>{`Rendelés azonosító: ${paymentDetails?.orderId}`}</p>
                    <p className={styles.IdText}>
                      {paymentDetails?.paymentVendorType?.includes('OTP_SIMPLE')
                        ? `SimplePay tranzakció azonosító: ${externalId}`
                        : `Barion tranzakció azonosító: ${externalId}`}
                    </p>
                    <a className={styles.homeButton} href='/'>
                      Vissza a főoldalra
                    </a>
                  </div>
                </>
              )}
            </div>
          </Grow>
        )}
      </div>
    </main>
  );
};
