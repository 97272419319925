/** @format */

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeProvider } from '@material-ui/styles';
import MainTheme from 'styles/MainTheme';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import './styles/common.css';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Landing from 'pages/Landing/Landing';
import Group from 'pages/Group/Group';
import Contact from 'pages/Contact/Contact';
import Cart from 'pages/Cart/Cart';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { getCartById } from 'redux/slices/CartSlice';
import { setDocuments } from 'redux/slices/DocumentSlice';
import { PaymentDetails } from 'pages/PaymentDetails/PaymentDetails';
import Checkout from 'pages/Cart/Checkout/Checkout';
import DataTransfer from 'pages/DataTransfer/DataTransfer';
import SIZES from 'constants/sizes';
import NotificationDialog from 'components/NotificationDialog/NotificationDialog';
import { useShopApi } from 'hooks/useShopApi';
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { useNotificationAPI } from 'components/NotificationDialog/hooks/useNotificationAPI';
import CustomerFAQ from 'pages/CustomerFAQ/CustomerFAQ';
import PhotographerFAQ from 'pages/PhotographerFAQ/PhotographerFAQ';
import BlogSubpage from 'pages/BlogSubpage/BlogSubpage';
import Blog from 'pages/Blog/Blog';
import PhotographerInfoPage from 'pages/InfoPages/PhotographerInfoPage/PhotographerInfoPage';
import InstitutionInfoPage from 'pages/InfoPages/InstitutionInfoPage/InstitutionInfoPage';
import EmbeddedLanding from 'pages/EmbeddedLanding/EmbeddedLanding';
import PhotographerProfile from 'pages/PhotographerProfile/PhotographerProfile';

const BodyWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${MainTheme.palette.primary.backgroundContent};
`;

const HeaderWrapper = styled.div`
  margin-bottom: 75px;
  @media (min-width: ${SIZES.MD}px) {
    margin-bottom: 0;
  }
`;

const FooterWrapper = styled.div`
  margin-top: auto;
  z-index: 50;
`;

const App = () => {
  const dispatch = useDispatch();

  const [showValidUntil, setShowValidUntil] = useState(true);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const { shopData, getShopById } = useShopApi();
  const { getBaseDocuments, baseDocuments } = useDocumentsApi();
  const [cartId, setCartId] = useState(null);

  const [accessKey, setAccessKey] = useState(null);
  const [requiredActions, setRequiredActions] = useState();

  const { notifications, fetchNotifications } = useNotificationAPI();

  const initCart = async () => {
    if (localStorage.getItem('cartId')) {
      await dispatch(
        getCartById({
          id: cartId ? cartId : parseInt(localStorage.getItem('cartId')),
          accessKey: accessKey ? accessKey : localStorage.getItem(localStorage.getItem('cartId'))
        })
      )
        .then((resp) => {})
        .catch((e) => {});
    }
  };

  useEffect(() => {
    getShopById(shopId);
  }, [shopId]);

  useEffect(() => {
    if (cartId) {
      localStorage.setItem('cartId', cartId);
    }
    initCart();
  }, [cartId]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (notifications) {
      const actions = [];
      notifications?.forEach((n) => n.requiredActions?.forEach((action) => actions.push(action.action)));
      setRequiredActions(actions);
    }
  }, [notifications]);

  useEffect(() => {
    getBaseDocuments();
  }, []);

  useEffect(() => {
    dispatch(setDocuments(baseDocuments));
  }, [baseDocuments]);

  return (
    <StyledComponentsThemeProvider theme={MainTheme}>
      <ThemeProvider theme={MainTheme}>
        <Router>
          <BodyWrapper>
            <HeaderWrapper>
              <Header shop={shopId} />
            </HeaderWrapper>
            <Switch>
              <Route exact path='/' children={<Landing />} />
              <Route
                exact
                path='/gallery/:idAndAccessKey'
                children={<Group showValidUntil={showValidUntil} setShowValidUntil={setShowValidUntil} />}
              />
              <Route exact path='/gallery' children={<EmbeddedLanding />} />
              <Route exact path='/contact' children={<Contact />} />
              <Route exact path='/customer-faq' children={<CustomerFAQ />} />
              <Route exact path='/photographer-faq' children={<PhotographerFAQ />} />
              {/*
              NEEDED IN PHOTOGRAPHER PROFILE VERSION
              shopId && <Route exact path='/fotos' children={<PhotographerProfile />} />
              */}

              <Route exact path='/adatkezelesi_tajekoztato' children={<DataTransfer />} />
              <Route exact path='/altalanos_szerzodesi_feltetelek' children={<DataTransfer />} />
              <Route exact path='/cart' children={<Cart actions={requiredActions} />} />
              {requiredActions?.find((action) => action === 'DISABLE_PAYMENT') ? (
                <Route exact path='/order-checkout' children={<Redirect to={`/cart`} />} />
              ) : (
                <Route exact path='/order-checkout' children={<Checkout shopData={shopData} />} />
              )}
              <Route exact path={'/blog'} children={<Blog />} />
              <Route exact path={`/blog/:slug`} children={<BlogSubpage />} />
              <Route exact path='/fotosokat-keresunk' children={<PhotographerInfoPage />} />
              <Route exact path='/oktatasi-intezmenyeket-keresunk' children={<InstitutionInfoPage />} />
              <Route
                exact
                path='/carts/:cartId'
                children={<Cart setCartId={setCartId} setAccessKey={setAccessKey} actions={requiredActions} />}
              />
              <Route path='/checkout/:checkoutId' children={<PaymentDetails />} />

              <Route path='*' children={<ErrorPage errorCode={404} errorText={'Content not found'} />} />
            </Switch>

            <ToastContainer
              position='bottom-right'
              autoClose={5000}
              hideProgressBar
              newestOnTop
              limit={5}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <FooterWrapper>
              <Footer shopData={shopData} />
            </FooterWrapper>
            <NotificationDialog notifications={notifications} />
          </BodyWrapper>
        </Router>
      </ThemeProvider>
    </StyledComponentsThemeProvider>
  );
};

export default App;
