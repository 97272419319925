import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import styles from './LoadIndicator.module.css';

const LoadIndicator = ({ color, height }) => {
  return (
    <div className={styles.loadingWrapper} style={{ height: height }}>
      <Typography style={{ color: color }}>Egy pillanat ...</Typography>
      <CircularProgress size={70} className={styles.spinner} style={{ color: color }} />
    </div>
  );
};

export default LoadIndicator;
