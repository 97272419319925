/** @format */

import { Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { MdDescription } from 'react-icons/md';
import { useFooterData } from './FooterData';
import styles from './Footer.module.css';
import simplepayPng from 'assets/images/simple_logo_newest.png';
import { useDocumentsApi } from 'hooks/useDocumentsApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FooterSubscribeForm from './FooterSubscribeForm/FooterSubscribeForm';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const baseDocuments = useSelector((state) => state.nonPersistedReducers?.documentSliceReducer?.documents);

  const { FooterData } = useFooterData(props?.shopData);

  const { getDocuments, downloadDocuments, downloadBaseDocuments, documents } = useDocumentsApi();

  useEffect(() => {
    if (shopId && shopId !== -1) {
      getDocuments();
    }
  }, [shopId]);

  const copyright = () => {
    return (
      <Typography
        align='center'
        style={{
          fontFamily: `var(--font-secondary), Sans-serif, Arial`
        }}>
        {'Copyright © '}
        <RouterLink className={styles.link} to='/'>
          iskolaévkönyv.hu
        </RouterLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  };

  return (
    <div className={styles.outerWrapper} id='hirlevel'>
      <div className={styles.footerWrapper}>
        <FooterSubscribeForm />
        <div className={styles.sections}>
          {FooterData.sections.map((section, secIdx) => {
            return (
              <div className={styles.sectionWrapper} key={`footer_section_${secIdx}`}>
                <div className={styles.titleWrapper}>
                  <p className={styles.sectionTitle}>{section?.name}</p>
                </div>
                <div className={styles.sectionContent}>
                  {section?.links?.map((link, idx) => {
                    return (
                      <Fragment key={`footer_section_${secIdx}_link_${idx}`}>
                        {link?.external ? (
                          <>
                            <a target='_blank' href={link?.link} rel='noreferrer' className={styles.sectionA}>
                              {link?.icon} {link?.text}
                            </a>
                          </>
                        ) : (
                          <>
                            <Link
                              onClick={link?.to === '#' ? () => {} : () => window.scrollTo(0, 0)}
                              className={styles.sectionLink}
                              to={link?.link}>
                              {link?.icon} {link?.text}
                            </Link>
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {FooterData.downloadSections.map((section, downIdx) => {
            return (
              <div className={styles.sectionWrapper} key={`footer_download_${downIdx}`}>
                <div className={styles.titleWrapper}>
                  <p className={styles.sectionTitle}>{section?.name}</p>
                </div>
                <div className={styles.sectionContent}>
                  {section?.name === 'Dokumentumok'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('szerződési')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={styles.sectionA}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />{' '}
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? baseDocuments?.map((el) => {
                        if (el.name.includes('tájékoztató')) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadBaseDocuments(el.id, el.name);
                                }}
                                className={styles.sectionA}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />{' '}
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.links?.map((link, idx) => (
                    <Fragment key={`footer_download_${downIdx}_link_${idx}`}>
                      {link?.external ? (
                        <a target='_blank' href={link?.link} rel='noreferrer' className={styles.sectionA}>
                          {link?.icon} {link?.text}
                        </a>
                      ) : (
                        <a href={link?.href} download={link?.download} className={styles.sectionA} key={idx}>
                          {link?.icon} {link?.text}
                        </a>
                      )}
                    </Fragment>
                  ))}

                  {section?.name === 'Dokumentumok'
                    ? documents?.map((el) => {
                        if (el.name.includes('Általános szerződési feltételek') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={styles.shopLink}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />{' '}
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                  {section?.name === 'Adatvédelem'
                    ? documents?.map((el) => {
                        if (el.name.includes('Adatkezelési tájékoztató') && shopId) {
                          return (
                            <Fragment key={`footer_download_${el.id}_link_${el.id}`}>
                              <a
                                onClick={(event) => {
                                  event.preventDefault();
                                  downloadDocuments(el.id, el.name);
                                }}
                                className={styles.sectionA}
                                key={el.id}
                                href='/'>
                                <MdDescription
                                  style={{
                                    color: 'var(--primary-background-secondary)'
                                  }}
                                />{' '}
                                {el.name}
                              </a>
                            </Fragment>
                          );
                        }
                      })
                    : ''}
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.logoWrapper}>
          <a href='https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf' target={'_blank'} rel='noreferrer'>
            <img className={styles.logo} src={simplepayPng} alt='simplepay_logo' />
          </a>
        </div>

        {/* <PaymentVendorLogoWrapper>
                    <a
                        href="https://secure.barion.com/Registration/Individual?lang=hu_HU"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <PaymentVendorLogo
                            src={barionStrip}
                            alt="barion_logo"
                        />
                    </a>
                </PaymentVendorLogoWrapper> */}
        <div className={styles.copyrightWrapper}>
          <div className={styles.sectionLink}>{copyright()}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
