import { useEffect, useState } from 'react';
import { TextField, Button, FormControlLabel } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import invertedLogo from 'assets/images/svg/iskolaevkonyv_logo_szines-feliratnelkul.svg';
import { Formik, Form } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import { ContactSchema } from 'validation/Contact/Schemas';
import { Divider } from '@material-ui/core';
import { useContactAPI } from './hooks/useContactAPI';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import contact_image from 'assets/images/svg/contact.svg';
import { Checkbox } from '@mui/material';
import styles from './Contact.module.css';

const Contact = () => {
  const { sendEmail } = useContactAPI();
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [addressData] = useState([
    {
      name: 'Email cím',
      data: 'ugyfelszolgalat@iskolaevkonyv.hu',
      icon: <MdOutlineEmail style={{ color: 'black' }} size={20} />
    },
    {
      name: 'Telefonszám',
      data: '+36 70 392 7242',
      icon: <MdOutlinePhone style={{ color: 'black' }} size={20} />
    }
  ]);

  useEffect(() => {
    document.body.style.overflow = 'visible';
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const captchaRef = useRef();

  return (
    <main>
      <Helmet>
        <title>Iskolaévkönyv - Kapcsolat</title>
        <meta
          name='description'
          content='Lépj kapcsolatba velünk, ha kérdésed van intézményi fotózás, iskolai fotózás vagy bölcsődei fotózás témában.'
        />
      </Helmet>
      <div className={styles.center}>
        <Slide direction='down' in={true} timeout={1000} unmountOnExit>
          <div className={styles.contactContainer}>
            <div>
              <div className={styles.header}>
                <div className={styles.portraitWrapper}>
                  <img className={styles.portraitImage} src={invertedLogo} alt='Portrait of the photographer' />
                </div>
                <div className={styles.name}>Iskolaévkönyv.hu</div>
              </div>
              <p>
                Töltsd ki a kapcsolati űrlapot, vagy lépj velünk kapcsolatba e-mailben/telefonon. Ügyfélszolgálatunk
                készséggel segít mindenben, legyen szó kérdésekről, ötletekről vagy visszajelzésekről.
              </p>
              <p>
                Célunk, hogy a lehető legjobb élményt nyújtsuk, és minden igényedre személyre szabott megoldást
                találjunk. Munkatársaink elkötelezettek abban, hogy gyors és hatékony támogatást nyújtsanak, mert
                tudjuk, hogy az időd értékes.
              </p>
              <br />
              {addressData &&
                addressData?.map((address) => (
                  <div key={address.name} className={styles.datas}>
                    <div>{address.icon}</div>
                    <div
                      style={{
                        wordBreak: 'break-word', // Tördelje a szavakat
                        overflowWrap: 'break-word', // Alternatív mód modern böngészőkhöz
                        whiteSpace: 'normal', // Engedélyezi a szöveg tördelését
                        maxWidth: '100%' // Biztosítsd, hogy ne legyen túl széles
                      }}>
                      {address.data}
                    </div>
                  </div>
                ))}
              <img
                alt='Intézményeket keresünk az iskolaévkönyvhöz.'
                src={contact_image}
                className={styles.contactIllustration}
              />
            </div>

            <div className={styles.formContainer}>
              <Divider orientation='vertical' className={styles.verticalDivider} />
              <Divider orientation='horizontal' className={styles.horizontalDivider} />
              <Formik
                enableReinitialize
                initialValues={{
                  name: formData.name,
                  email: formData.email,
                  message: formData.message,
                  shopId: shopId > 0 ? shopId : null
                }}
                style={{ width: '100%' }}
                validate={false}
                validationSchema={ContactSchema}
                onSubmit={async (values, { setSubmitting, resetForm, setFieldValue }) => {
                  setFormData({
                    name: values.name,
                    email: values.email,
                    message: values.message
                  });

                  const token = captchaRef.current.getValue();
                  captchaRef.current.reset();
                  if (token) {
                    await sendEmail({
                      values: values,
                      resetForm: setFieldValue,
                      token: token
                    });
                  }
                }}>
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, resetForm }) => (
                  <Form onSubmit={handleSubmit} className={styles.formWrapper}>
                    <h1>Kérdésed van?</h1>
                    {/* Forms */}
                    <TextField
                      id='name'
                      name='name'
                      autoComplete='name'
                      value={values.name}
                      autoFocus
                      label='Neved'
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant='outlined'
                    />
                    <TextField
                      id='email'
                      name='email'
                      autoComplete='email'
                      value={values.email}
                      label='Email címed'
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      variant='outlined'
                    />
                    <TextField
                      variant='outlined'
                      name='message'
                      label='Üzeneted'
                      fullWidth
                      margin='normal'
                      multiline
                      rows={6}
                      value={values.message}
                      onChange={handleChange}
                      error={touched.message && Boolean(errors.message)}
                      helperText={touched.message && errors.message}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={privacyAccepted} onChange={() => setPrivacyAccepted(!privacyAccepted)} />
                      }
                      label={
                        <>
                          Az{' '}
                          <a
                            href={`${window._env_.REACT_APP_QDAK_REGISTRATION_URL?.split('admin.')[0]}${
                              window._env_.REACT_APP_QDAK_REGISTRATION_URL?.split('admin.')[1]?.split('/register')[0]
                            }/adatkezelesi_tajekoztato`}
                            target='_blank'
                            rel='noreferrer'>
                            Adatkezelési tájékoztatót
                          </a>{' '}
                          elolvastam, az abban foglaltakat elfogadom.
                        </>
                      }
                    />
                    <ReCAPTCHA
                      sitekey={window._env_.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                      onChange={() => {
                        setDisabled(
                          captchaRef?.current?.getValue() == '' || captchaRef?.current?.getValue() == undefined
                        );
                      }}
                    />
                    <Button
                      className={styles.submitButton}
                      type='submit'
                      color='primary'
                      onClick={handleSubmit}
                      disabled={disabled || !privacyAccepted}>
                      Küldés
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Slide>
      </div>
    </main>
  );
};

export default Contact;
