import { Button, Dialog, Slide } from '@material-ui/core';
import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styles from './NotificationDialog.module.css';

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const NotificationDialog = ({ notifications }) => {
  const [open, setOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [visibleNotifications, setVisibleNotifications] = useState();

  useEffect(() => {
    if (notifications?.length > 0) {
      setVisibleNotifications(
        // Currently unnecessary new method needed for checking previously readed notifications
        // notifications?.filter(
        //   (n) => !localStorage.getItem(`n${n?.id}confirmed`)
        // )
        notifications
      );
    }
  }, [notifications]);

  return (
    <>
      {visibleNotifications?.length > 0 ? (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          className={styles.dialog}
          PaperProps={{
            style: {
              width: '100%',
              maxWidth: '1000px',
              padding: '2rem',
              margin: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              zIndex: 20,
              background: 'var(--primary-text-primary)'
            }
          }}
          onClick={(e) => e.stopPropagation()}
          onClose={() => {}}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <>
            <h1 className={styles.title}>{visibleNotifications[activeStep]?.message}</h1>
          </>
          {visibleNotifications?.length > 1 && (
            <div className={styles.stepper}>
              {notifications?.map((notification, idx) => {
                return (
                  <div
                  className={styles.stepIndicator}
                    key={notification.id}
                    style={{
                      background: activeStep === idx ? 'var(--primary-background-primary)' : 'lightgrey'
                    }}
                    onClick={() => setActiveStep(idx)}
                  />
                );
              })}
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            {visibleNotifications?.length > 1 && (
              <Button
                disabled={activeStep === 0}
                className={styles.backButton}
                style={{
                  background: activeStep !== 0 ? 'var(--primary-background-primary)' : 'lightgrey'
                }}
                onClick={() => setActiveStep(activeStep - 1)}>
                Vissza
              </Button>
            )}

            <Button
              className={styles.button}
              onClick={() => {
                !localStorage.getItem(`n${visibleNotifications[activeStep]?.id}confirmed`) &&
                  localStorage.setItem(`n${visibleNotifications[activeStep]?.id}confirmed`, true);
                activeStep === notifications?.length - 1 ? setOpen(false) : setActiveStep(activeStep + 1);
              }}>
              {activeStep === visibleNotifications?.length - 1 ? 'Megértettem' : 'Tovább'}
            </Button>
          </div>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationDialog;
