import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ItemCounter from './ItemCounter/ItemCounter';
import styles from './CartItem.module.css';
import { formatPrice } from 'utils/Converters';
import { IconButton } from '@material-ui/core';

const CartItem = (props) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.imageWrapper}>
        <img className={styles.itemImage} src={props.thumbnail} alt={props.name} />
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.topSection}>
          <div className={styles.itemInfo}>
            <h3 className={styles.itemName}>
              <a href='#'>{props.id}</a>
            </h3>
            <div className={styles.itemDetails}>
              <span>{formatPrice(props.total)} HUF</span>
            </div>

            {!props?.category?.includes('email') && props.increment && props.decrement && (
              <ItemCounter
                category={props.category}
                count={props.count}
                decrement={props.decrement}
                increment={props.increment}
              />
            )}
          </div>

          {props.delete && (
            <IconButton className={styles.deleteButton} onClick={props.delete}>
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </div>

        {props.showStock && (
          <div className={styles.stockStatus}>
            <CheckIcon style={{ fontSize: 20 }} />
            <span>Készleten</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
